.img{
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
.description{
    width: 80%;
}

.address{
    width: 50%;
}
.mapContainer{
    display: flex !important;
}
.map{
    width: 50% !important;
}
@media only screen and (max-width:600px) {
    .map{
        width: 100% !important;
        height: 30vh !important;
    }
    .mapContainer{
        display: unset !important;
    }
    .address{
        width: 100% !important;

    }
}