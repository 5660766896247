.parentContainer{
    position: absolute;
background-color: rgba(0,0,0,0.5);
}
.leftContainer{
    width: 50%;
    float: left;
    padding: 10px 0;
}
.rightContainer{
    width: 50%;
    float: left;
    padding: 10px 0;
}
.inputParent{
  display: flex;
}
/* label{
    color: black;
    font-size: 15px;
    font-weight: bold;
} */
.input{
    font-size: 15px;
    /* letter-spacing: 1px; */
    outline: unset;
    height: 45px;
    width: 95%;
    padding: 0 10px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    margin-top: 4px;
    
}
.showImgContainer{
  width: 115px;
height: 66px;
}
.textarea{
  font-size: 15px;
  letter-spacing: 1px;
  outline: unset;
  height: 80px;
  width: 97.5%;
  padding: 10px 10px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 6px;
  margin-top: 4px;
  resize: unset;
}
select option[disabled]:first-child {
    display: none;
  }

  .select{
    font-size: 15px;
    letter-spacing: 1px;
    outline: unset;
    height: 45px;
    width: 95%;
    padding: 0 10px;
    background: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    margin-top: 4px;
  }
  .imgUpload{
    width: 30px;
    height: 30px;
    align-self: center;
    cursor: pointer;
    align-self: center;
  }
  .imgUploadContainer{
      height: 50px;
      display: flex;
      margin-top: 5px;
      width: 95%;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 6px;
      padding: 0 15px;
  }
  .imgInput{
align-self: center;
  }
  .btn{
    background-color: #F27F0C;
border: none;
border-radius: 6px;
color: white;
font-size: 17px;
padding: 10px 46px;
text-transform: uppercase;
/* margin-left: 15px; */
font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .cancelBtn{
    background-color: white;
border: 1px solid #F27F0C;
border-radius: 6px;
color: #F27F0C;
font-size: 17px;
padding: 10px 46px;
text-transform: uppercase;
/* margin-left: 15px; */
font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .Resetbtn{
    background-color: white;
    border: 1px solid #F27F0C;
    border-radius: 6px;
    color: #F27F0C;
    font-size: 17px;
    padding: 10px 46px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 15px;
  }
  .errMsg{
    font-size: 12px;
    font-weight: bold;
  }
  .cancelImg{
    width: 20px;
    height: 20px;
    align-self: center;
  }
  .cancelImgContainer{
    width: 30px;
height: 30px;
background-color: #f5f5f5;
border-radius: 50%;
display: flex;
justify-content: center;
cursor: pointer;
  }
  .cancelImgParentContainer{
display: flex;
justify-content: space-between;
  }

  .btnContainer{
    width: 100%;
display: flex;
justify-content: end;
padding: 0px 30px;
margin-top: 10px;
  }

  @media only screen and (max-width:450px) {
  .inputParent{
    display: block;
  }
  .input{
    width: 100%;
  }
  .leftContainer{
    width: 100%;
  }
  .rightContainer{
    width: 100%;
  }
  .btnContainer{
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .imgUploadContainer{
    width: 100%;
  }
  .select{
    width: 100%;
  }

  }

  @media (min-width:601px) and (max-width:768px) {
    .inputParent{
      display: block;
    }
    .leftContainer{
      width: 100%;
    }
    .rightContainer{
      width: 100%;
    }
  }

  @media (min-width:769px) and (max-width:992px) {
      
  }

  @media (min-width:993px) and (max-width:1140px) {
      
  }

  @media (min-width:1141px) and (max-width:1200px) {
      
  }