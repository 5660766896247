.parentContainer {
  width: 100%;
  height: 100vh;
  display: flex;
}

.childContainer {
  width: 390px;
  height: 400px;
  margin: auto;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.img {
  width: 180px;
}
.text {
  color: white;
  font-size: 15px;
  font-weight: bold;
}
.input {
  width: 98%;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  outline: unset;
  font-size: 14px;
  border-radius: 5px;
}
.visibleImg {
  width: 22px;
  height: 22px;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
}
.inputParent {
  display: flex;
  background: #fff;
  border-radius: 5px;
  height: 41px;
}
.btn {
  background-color: #ff7925;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 60px;
  margin: 20px 0;
  border-radius: 5px;
  text-transform: uppercase;
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.errMsg {
  font-size: 13px;
  font-weight: bold;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  width: 355px;
}
