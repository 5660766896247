@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #fff1e7;
}

html {
  overflow: auto;
}

.fontBold {
  font-size: 20px;
  font-weight: bold;
}

.contentFont {
  font-size: 13px;
  font-weight: 500;
}

.label {
  font-size: 15px;
  font-weight: 700;
}

.header {
  font-size: 14px;
  font-weight: bold;
}

.modalHeading {
  font-size: 16px !important;
  font-weight: bold !important;
}

.modalHeading .accordion-button {
  font-size: 16px !important;
  font-weight: bold !important;
}

p {
  margin: 0;
}

.datetime-reset-button {
  display: none !important;
}

.cp {
  cursor: pointer;
}

.dropDownIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 23px;
  bottom: 15px;
}

.orange {
  color: #ff7b26;
}

.bg_orange {
  background-color: #ff7b26 !important;
}

.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.dropdownHeading {
  font-size: 15px;
  font-weight: 500;
}

.options {
  font-size: 12px;
}

.mainslick .slick-slide {
  padding: 25px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  align-self: center;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #ff7b26;
}

input:focus+.slider {
  box-shadow: 0 0 1px #ff7b26;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* @font-face {
  font-family: kstore;
  src: url("../../kstore/src/fonts/Open_Sans/OpenSans-Bold.ttf");
}

.kstoreFontBold{
  font-family: kstore;
} */

/* @font-face {
  font-family: kstore2;
  src: url("../../kstore/src/fonts/Open_Sans/OpenSans-Medium.ttf");
}

.kstoreFontMedium{
  font-family: kstore2;
}

@font-face {
  font-family: kstore3;
  src: url("../../kstore/src/fonts/Open_Sans/OpenSans-Light.ttf");
}

.kstoreFontLight{
  font-family: kstore3;
}

@font-face {
  font-family: kstore4;
  src: url('../../kstore/src/fonts/Signika-Bold.ttf');
}
.kstoreFont4{
  font-family: kstore4;
}
@font-face {
  font-family: kstore5;
  src: url('../../kstore/src/fonts/Signika-Medium.ttf');
}
.kstoreFont5{
  font-family: kstore5;
}
@font-face {
  font-family: kstore6;
  src: url('../../kstore/src/fonts/Signika-Regular.ttf');
}
.kstoreFont6{
  font-family: kstore6;
} */

.moneyBtn {
  color: white;
  border: none;
  padding: 6px 17px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: bold;
}

.page-item.active .page-link {
  background-color: white !important;
  color: #ff7b26 !important;
}

/* .carousel-control-prev-icon, .carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: 100%, 100%;
  border-radius: 50%;
}
.carousel-control-prev {
  left: -22px !important;
}
.carousel-control-prev-icon{
  background-image: url("../../kstore/src/Assets/modal_left_arrow.png") !important;
  width: 20px !important;
  height: 27px !important;
  display: inline-block;
  background: no-repeat;
} */
/* .carousel-control-prev {
  left: -22px !important;
}
.carousel-control-prev-icon{
  background-image: url("../../kstore/src/Assets/modal_left_arrow.png") !important;
  width: 20px !important;
  height: 27px !important;
  display: inline-block;
  background: no-repeat;
}
.carousel-control-next-icon{
  background-image: url("../../kstore/src/Assets/modal_right_arrow.png") !important;
  width: 20px !important;
  height: 27px !important;
  display: inline-block;
  background: no-repeat;
}
.carousel-control-next {
  right: -22px !important;
}
.carousel-inner{
  display: flex !important;
} */
.accordion-button {
  padding: 15px 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: #ff7b26;
}

.accordion-button:focus {
  box-shadow: unset !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("Assets/cross.png") !important;
}

.accordion-button::after {
  background-image: url("Assets/plus.png") !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrDateRangePickerWrapper {
  position: absolute;
  right: 7%;
  top: 20%;
}

.css-b62m3t-container {
  width: 100%;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: unset !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
  box-shadow: unset !important;
  border-color: black !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 15px !important;
  border: unset !important;
}

.Mui-focused fieldset {
  border: rgb(0, 0, 0) !important;
}

table thead {
  background: #f77a26;
  color: #fff;
}

/* timer */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: unset !important;
}

/* City */
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-pdq7eq-MuiAutocomplete-root .MuiOutlinedInput-root {
  color: white !important;
}

.pagination li a {
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: 500;
  padding: 2px !important;
}

.modal-content {
  background: #fff5ed;
  border-radius: 40px;
}

.react-multi-carousel-list {
  padding: 0;
}

.react-multi-carousel-track {
  justify-content: center;
  align-items: center;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
}

.react-multiple-carousel__arrow::before {
  padding: 3px 7px 0 7px !important;
  font-size: 15px !important;
}

.bg-white {
  border-radius: 30px;
  padding: 15px;
  float: left;
  width: 100%;
}

.customerCart table tbody tr {
  border: 1px solid #ddd;
  background: transparent !important;
  border-radius: 10px;
}

.customerCart table thead {
  background: transparent !important;
  color: #000 !important;
  font-size: 13px;
  font-weight: normal !important;
  padding-bottom: 10px;
}

.customerCart table thead th {
  padding-bottom: 5px;
}

@media only screen and (max-width:450px) {
  .react-multi-carousel-track {
    height: auto !important;
    min-height: auto !important;
  }

  .rdrDateRangePickerWrapper {
    right: -28px;
    top: 100px;
  }

  .offcanvas {
    margin: 15px;
    border-radius: 20px;
  }
}

@media (min-width:769px) and (max-width:992px) {
  .rdrDateRangePickerWrapper {
    right: 14%;
  }

  .moneyBtn {
    font-size: 15px;
    padding: 10px 70px;
  }
}

.infinite-scroll-component {
  width: 350px;
  background-color: #1f1d1d !important;
  margin-right: 20px;
  /* border-radius: 7px;   */
}

.disclaimer {
  margin-top: 10px;
  color: #f46901;
  text-align: center;
  font-size: 15px,
}

@media (max-width:769px) {
  .disclaimer {
    font-size: 11px;

  }
}