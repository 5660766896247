.overallContainer {
  width: 100%;
  float: left;
}
.overall {
  overflow: auto;
  max-height: 440px;
  margin-bottom: 10px;
}
.leftContainer {
  width: 280px;
  float: left;
  padding: 21px;
  background: #fff;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  height: calc(100vh - 100px);
  border-radius: 40px;
  margin: 20px 0 20px 20px;
  border-radius: 30px;
}
.clearall {
  font-size: 12px;
  font-weight: bold;
  align-self: center;
}
.heading {
  cursor: pointer;
  margin-top: 10px;
}
.hr1 {
  height: 3px !important;
  width: 25%;
  color: #ff7c25;
}
.paginationContainer {
  justify-content: center;
  margin-top: 15px;
}
.hr2 {
  height: 3px !important;
  width: 75%;
}
.searchInput {
  border: none;
  outline: unset;
  padding: 5px 15px;
  width: 91%;
}
.searchChildContainer {
  display: flex;
  background: #fff;
  width: 30%;
  padding: 7px 11px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 40px;
  justify-content: space-between;
}
.searchImg {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.rightContainer {
  width: calc(100% - 300px);
  float: left;
  padding: 20px 20px;
  min-height: calc(100vh - 68.5px);
}
.selectContainer {
  width: 72%;
  align-self: center;
}
.select {
  border: none;
  box-shadow: unset;
}
.mblFilter {
  display: none;
}
.select {
  border: none !important;
  box-shadow: unset !important;
  font-size: 12px !important;
  padding: 15px 8px !important;
  border-radius: 9px !important;
}
.rightFilterContainer {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  /* padding: 0 2.5% !important; */
}
.sort {
  font-size: 12px !important;
}
.sortText {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
select option[disabled]:first-child {
  display: none;
}
.empty {
  border: none !important;
  background-color: white;
  width: 100%;
  box-shadow: unset !important;
  font-size: 12px !important;
  padding: 12px 5px;
  border-radius: 40px;
  cursor: pointer;
  padding-right: 0px !important;
}
.notFoundImgContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.notFoundImg {
  align-self: center;
  width: 250px;
}
.clearfilterText {
  font-size: 15px;
  align-self: center;
  width: 50%;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
  color: #f77a26;
  cursor: pointer;
}
.clearFilterContainer {
  display: flex;
  width: 330px;
}
.mblcart {
  width: 50px;
  position: fixed;
  height: 50px;
  border-radius: 50%;
  background: #f77a26;
  display: flex;
  justify-content: center;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  z-index: 1;
  box-shadow: rgb(156 156 156 / 32%) -1px 0px 6px 2px;
}
.cartImg {
  width: 25px;
  height: 25px;
  align-self: center;
}
.applyBtn {
  /* background-color: #ff8537; */
  border: none;
  color: #ff8537;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  /* padding: 0 30px; */
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  /* text-decoration: underline; */
}
.notification {
  background-color: #2b9f00;
  min-width: 17px;
  position: absolute;
  top: 3px;
  border-radius: 50%;
  min-height: 17px;
  display: flex;
  justify-content: center;
  right: 6px;
}
.badge {
  font-size: 12px;
  align-self: center;
  color: white;
  font-weight: bold;
}
.filter {
  display: none;
}
@media only screen and (max-width: 450px) {
  .leftContainer {
    display: none;
  }
  .mblFilter {
    display: block;
  }
  .rightContainer {
    width: 100% !important;
  }
  .rightFilterContainer {
    display: block;
  }
  .searchChildContainer {
    width: 100%;
  }
  .filter {
    width: 90px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    text-transform: uppercase;
    margin-right: 10px;
  }
.clearFilterContainer{
  justify-content: flex-end;
}
  .mblProducts {
    justify-content: space-evenly !important;
    margin-top: 25px;
  }
  .leftHeading {
    font-size: 12px !important;
  }
  .sortText {
    width: 60% !important;
  }
  .rightContainer {
    padding: 20px 15px !important;
  }
  .mblFilterContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100% !important;
  }
  .filter {
    width: 100px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: white;
    cursor: pointer;
    position: absolute;
    right: 48px;
    top: 99px;
    padding: 4px 0;
    text-transform: uppercase;
  }
  .sortText {
    width: 35% !important;
  }
  .mblFilter {
    display: block;
  }
  .selectContainer {
    align-self: center !important;
  }
  .mblFilterContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100%;
  }
}

