.imgContainer {
  background: #111;
  border-radius: 10px;
  padding: 15px;
  color: #bbb;
  /* margin-bottom: 25px; */
  width: 254px;
  overflow: hidden;
  margin-right: 15px;
  min-height: 303.2px;
}
.img {
  display: block;
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.mapContainer {
  overflow: hidden;
  height: 346px;
}
.video {
  width: 80%;
  height: 300px;
}
.urlimg {
  width: 290px;
  height: 290px;
  object-fit: contain;
}
@media only screen and (max-width: 600px) {
  .imgContainer {
    width: 260px !important;
  }
}
