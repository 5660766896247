.success{
    font-size: 25px;
    color: #ff7b26;
    text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  }
  .overallContainer{
    width: 100%;
    min-height: calc(100vh - 68.5px);
    display: flex;
  justify-content: center;
  flex-direction: column;
}
  .imgContainer{
    display: flex;
    justify-content: center;
  }
  .childContainer{
    min-height: calc(90vh - 68.5px);
    background-color: #fff;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    flex-direction: column; border-radius: 30px;
  }
  .img{
    width: 70px;
    height: 70px;
  }