.cancelImg {
  width: 20px;
  height: 20px;
  align-self: center;
}

.cancelImgContainer {
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cancelImgParentContainer {
  display: flex;
  justify-content: space-between;
}

.msgIconContainer {
  display: flex;
  justify-content: center;
}

.msgIcon {
  width: 40px;
  height: 40px;
  align-self: center;
}

.msgIconParentContainer {
  width: 50px;
  height: 50px;
  /* background: #39B200; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.message {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.detailsParentContainer {
  max-width: 600px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detail {
  font-size: 14px;
  font-weight: 600;
}

.ImgContainer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

@media only screen and (max-width:450px) {
  .leftTitle {
    width: 149px;
  }

  .childParentContainer {
    width: 100%;
  }
}

@media (min-width:601px) and (max-width:768px) {}

@media (min-width:769px) and (max-width:992px) {}

@media (min-width:993px) and (max-width:1140px) {}

@media (min-width:1141px) and (max-width:1200px) {}