.cancelImg{
    width: 20px;
    height: 20px;
    align-self: center;
  }
  .cancelImgContainer{
    width: 30px;
height: 30px;
background-color: #f5f5f5;
border-radius: 50%;
display: flex;
justify-content: center;
cursor: pointer;
position: absolute;
right: 10px;
top: 4px;
  }
  .cancelImgParentContainer{
display: flex;
justify-content: space-between;
  }
.input{
    border: 1px solid rgba(0,0,0,0.1);
padding: 5px 15px;
border-radius: 5px;
width: 100%;
margin: 10px 0;
font-size: 15px;
}
.btn{
    background-color: #ff7b26;
border: none;
color: white;
font-size: 12px;
padding: 10px 40px;
font-weight: bold;
border-radius: 5px;
}
.btnContainer{
    display: flex;
    justify-content: end;
    padding: 5px 0;
}
.errMsg{
    font-size: 12px;
    font-weight: bold;
    color: red;
    text-align: center;
padding-right: 25px;
}
.priceContainer{
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width:450px) {
  .cancelImgContainer{
    width: 20px;
    height: 20px;
  }
  .cancelImg{
    width: 15px;
    height: 15px;
  }
}