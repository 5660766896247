

.checkbox {
    overflow: auto;
  }
  .checkboxContainer {
    height: 500px; 
    overflow: auto;
    scrollbar-color: #FF7C25 #fff;
  }
  .input {
    box-shadow: unset !important;
  }
  .showmore{
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
  }
  select option[disabled]:first-child {
    display: none;
  }

 .empty{
    border: none !important;
background-color: white;
width: 100%;
  }
  /* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */

  .dropdownBtn{
    background-color: white !important;
    box-shadow: unset !important;
    color: black !important;
    font-size: 17px !important;
    border-color: white !important;
    padding: 0 !important;
    margin: 10px 0;
  }
  /* .dropdownBtn::after{
    display: none !important;
  } */
  .dropdownOptionsParent{
    width: 100% !important;
    border: none !important;
    font-size: 12px !important;
    height: 150px !important;
    overflow: auto !important;
  }
  .dropdownItems{
    border-bottom: 1px solid rgba(0,0,0,0.05) !important;
    padding: 12px 0 !important;
  }
  .dropdownOptionsParent:hover{
    background-color: white !important;
  }
  .dropdownItems:hover{
    color: #FF7C25 !important;
    background-color: white !important;
  }
  .heading{
    padding: 5px 0;
    cursor: pointer;
  }
  .subHeading{
    font-size: 14px;
    letter-spacing: 1px ;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 0 15px;
  }