.overallContainer{
    width: 100%;
}

.checkbox {
    overflow: auto;
  }
  .checkboxContainer {
    /* height: 440px;  */
    overflow: auto;
    scrollbar-color: #FF7C25 #F8F3F0;
    display: flex;
  }
  .dropdownOptions{
      position: initial !important;
  }
  .input {
    box-shadow: unset !important;
  }
  .showmore{
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
  }
  /* select option[disabled]:first-child {
    display: none;
  } */

 .empty{
    border: none !important;
background-color: white;
width: 100%;
  }
  /* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */
.active{
    color: #FF7C25 !important;
}
.inActive{
    color: black !important;
}
  .dropdownBtn{
    background-color: white !important;
    box-shadow: unset !important;  
    font-size: 17px !important;
    border-color: white !important;
    padding: 10px 15px !important;
    margin: 0 7px;
    border-radius: 5px;
  }
  .dropdownBtn::after{
    display: none !important;
  }
  .dropdownOptionsParent{
    width: 25% !important;
    border: none !important;
    font-size: 12px !important;
    /* height: 150px !important;
    overflow: auto !important;  */
  }
  .dropdownItems{
    border-bottom: 1px solid rgba(0,0,0,0.05) !important;
    padding: 12px 15px !important;

  }
  .dropdownOptionsParent:hover{
    background-color: white !important;
  }
  .dropdownItems:hover{
    color: #FF7C25 !important;
    background-color: white !important;
  }
  .dropdownBtn2{
    background-color: white !important;
    box-shadow: unset !important;  
    font-size: 17px !important;
    border-color: white !important;
    padding: 10px 15px !important;
    margin: 0 7px;
    border-radius: 5px;
    white-space: nowrap;
    display: inline-block;
font-weight: 400;
line-height: 1.5;
text-align: center;
text-decoration: none;
vertical-align: middle;
user-select: none;
border: 1px solid transparent;
border-radius: .25rem;
  }

  @media only screen and (max-width:450px) {
    .dropdownOptions{
      position: initial !important;
  }
  .dropdownOptionsParent{
    width: 60% !important;
    border: none !important;
    font-size: 12px !important;
    position: fixed !important;
  }
  .dropdownBtn2{
    margin: 0 10px !important;
  }
  .dropdownBtn{
    margin: 0 10px !important;
  }
  }

  @media (min-width:451px) and (max-width:768px) {
    .dropdownOptions{
      position: initial !important;
  }
  .dropdownOptionsParent{
    width: 40% !important;
    border: none !important;
    font-size: 12px !important;
    position: fixed !important;
  }
  .dropdownBtn2{
    margin: 0 10px !important;
  }
  .dropdownBtn{
    margin: 0 10px !important;
  }
  }

  @media (min-width:769px) and (max-width:992px) {
    .dropdownOptions{
      position: initial !important;
  }
  .dropdownOptionsParent{
    width: 40% !important;
    border: none !important;
    font-size: 12px !important;
    position: fixed !important;
  }
  .dropdownBtn2{
    margin: 0 10px !important;
  }
  .dropdownBtn{
    margin: 0 10px !important;
  }
  }

  @media (min-width:993px) and (max-width:1140px) {
    .dropdownOptions{
      position: initial !important;
  }
  .dropdownOptionsParent{
    width: 60% !important;
    border: none !important;
    font-size: 12px !important;
    position: fixed !important;
  }
  .dropdownBtn2{
    margin: 0 10px !important;
  }
  .dropdownBtn{
    margin: 0 10px !important;
  }
  }

  @media (min-width:1141px) and (max-width:1200px) {
    .dropdownOptions{
      position: initial !important;
  }
  .dropdownOptionsParent{
    width: 60% !important;
    border: none !important;
    font-size: 12px !important;
    position: fixed !important;
  }
  .dropdownBtn2{
    margin: 0 10px !important;
  }
  .dropdownBtn{
    margin: 0 10px !important;
  }
  }