.overallContainer{
    max-width: 282px !important;
    background-color: #ffe8d8;
    border: 1px solid #ffe2cd;   
    border-radius: 40px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px; */
    margin-top: 20px !important;
    margin-left: 15px;
    padding: 10px !important;
    float: left;
}
.offerContainer{
    width: 20%;
    height: 25px;
    background-color: #DD6A57;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    display: none;
}
.offer{
    color: white;
    font-size: 12px;
    font-weight: bold;
    align-self: center;
}
.productImg{
    max-width: 100%;
    max-height: 175px;
    cursor: pointer; border-radius: 40px;
}
.newPrice{
    font-size: 15px;
    font-weight: bold;
}
.oldPrice{
    font-size: 15px;
    text-decoration: line-through;
    color: rgba(0,0,0,0.65);
    margin-right: 5px;
}
.description{
    width: 100%;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    min-height: 42px;
    overflow: hidden;
}
.optionsContainer{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.02);
    border: 0.613514px solid rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4.90811px;
    margin: 2px 0;
}
.optionsImg{
    width: 18px;
    height: 18px;
    align-self: center;
}
.optionsParentContainer{
    position: absolute;
    right: 10px;
    bottom: 15px;
}
.customerAddBtn{
    background-color: #ff8a3e;
    font-size: 12px;
    color: white;
    font-weight: bold;
    border: none; float: right;
    padding: 6px 5px;
    text-transform: uppercase;
    width: 70px;
    border-radius: 30px;
    float: right;
}
.customerNewPrice{
    font-size: 17px; padding-right: 5px;font-weight: bold;
}
.priceDisplay { float: left; padding:10px 20px; width: 100%; }
.imgBox { background: #fff; width: 100%; min-height: 180px; border-radius: 40px; justify-content: center;  align-items: center; display: flex; }

@media (max-width:768px) {
    .overallContainer {
        max-width: calc(50% - 10px) !important;
        margin: 8px 5px 10px 5px !important;
     }
}
@media (max-width:450px) {
    .imgBox, .productImg { min-height:130px; max-height: 130px;}
    .overallContainer { border-radius: 25px;}
    .imgBox { border-radius: 20px; }
    .priceDisplay { text-align: center; }
    .customerAddBtn { float: none; margin-top: 5px; }
}