.nodataFound {
    margin: 10px;
    box-shadow: 0px 5px 4px rgba(204, 201, 201, 0.1);
    background-color: white;
    border-radius: 5px;
}

.nodatatext {
    font-weight: bold;
    color: #ff7b26;
}