.overallContainer{
    width: 100%;
    float: left;
    display: flex;
}

.leftContainer{
    width: 50%;
    float: left;
    padding: 5px 25px;
    /* align-self: center; */
}
.rightContainer{
    width: 50%;
    float: left;
    max-height: 576px;
overflow: auto;
padding-right: 10px;
}
/* .magnifierContainer {
  
    background-color: #FF7C25;

} */
.imgContainer{
    /* width: 50%; */
height: 100%;
width: 100px;
display: flex;
align-items: center;
justify-content: center;
}
.smallSliderContainer{
    background-color: #f5f5f5;
    margin-top: 30px;
    height: 130px;
    display: flex;
    /* align-items: center; */
    padding: 5;
}
.modalImg{
    width: 550px;
    height: 379px;
    object-fit: cover;
    margin-right: 10px;
    z-index: 100;
    /* height: 350px; */
}
.thumbnailimg:hover {
    cursor: pointer;
    border: 1px solid black;
}
.enlargedOverLaySec {
    position: absolute;
    z-index: 1;
    right: 20%;
    top: 45px;
    
}
.enlargeImage{
    z-index: 1000;
   
}
.unitSelectContainer{
   
    background: #f5f5f5;
    border: none;
    height: 33px;
    padding: 0px 8px;
    margin-left: 25px;
    outline: unset;
}
.thumbnailimg {
    height: 130px;
    width: 130px !important;
    object-fit: cover;
}
.videoContainer{
    width: 100% !important;
}
.modalVideo{
    width: 450px;
    height: 379px;
    
}
.sliderContainer{
    background-color: #f5f5f5;
    z-index: 1;
    
    /* background-color: #FF7C25; */
 
}
.sliderContainer img {
    margin-right: 10px;
}

.smallVideoImageContainer{
    margin-top: 35px;
    background-color: #f5f5f5;
}

.modalImgContainer{
   
   
    display: flex;
    width: calc(100% - 100px);
    justify-content: center;
    padding: 15px;
}
.modalSmallImg{
    width: 100px !important;
}
.modalSmallImgContainer{
    width: 30%;
background: #f5f5f5;
display: flex;
justify-content: center;
padding: 15px 11px;
margin: 0 2.5%;
}
.modalItemContainer{
    display: flex !important;
    flex-wrap: wrap;
    height: 140px;
}
.cancelContainer{
    width: 25px;
height: 25px;
background: rgba(0, 0, 0, 0.05);
border-radius: 50%;
display: flex;
justify-content: center;
cursor: pointer;
}
.rightDescription{
    font-size: 11px;
font-weight: bold;
}
.oldPrice{
    font-size: 15px;
color: rgba(0, 0, 0, 0.6);
text-decoration: line-through;
margin-right: 15px;
}
.newPrice{
    font-size: 16px;
font-weight: bold;
}
.productCount{
    width: 85px;
background: #f5f5f5;
border: none;
height: 33px;
padding: 0px 8px;
margin-left: 25px;
outline: unset;
}
.addtocartBtn{
    background: #FF7C25;
border: none;
color: white;
text-transform: uppercase;
font-size: 12px;
font-weight: bold;
padding: 10px 75px;
margin-left: 25px;
}
.likeContainer{
    width: 30px;
height: 30px;
background: #FFBA5F;
border-radius: 50%;
display: flex;
justify-content: center;
margin-right: 15px;
cursor: pointer;
}
.likeImg{
    width: 13px;
}
.title{
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
    align-self: center;
    margin-right: 10px;
}
.offerImg{
    width: 17px;
margin-right: 10px;
}
.offerParentContainer{
    background: #f5f5f5;
width: 80%;
padding: 10px 15px;
}
.offerDescription{
    font-size: 12px;
font-weight: bold;
text-transform: capitalize;
}
.items{
    border: unset !important;
}
.description{
    font-size: 12px;
    font-weight: bold;
}
.reviewProfileContainer{
    width: 80px;
height: 75px;
border: 1px solid;
border-radius: 10px;
}
.reviewProfile{
    width: 78px;
height: 73px;
object-fit: cover;
border-radius: 10px;
}
.profileContent{
    margin-left: 15px;
}
.profileDescription{
    font-size: 10px;
}
.input{
    padding: 10px 15px;
border: 1px solid rgba(0,0,0,0.1);
outline: unset;
}
.textarea{
    width: 100%;
resize: none;
border: 1px solid rgba(0,0,0,0.1);
height: 100px;
}
.ratings{
    color: rgba(0,0,0,0.5);
    align-self: center;
    margin-right: 10px;
}
.reviewUserRatingContainer{
    display: flex;
width: 37%;
justify-content: space-between;
align-self: center;
}
.reviewSubmitBtn{
    background: #FF7C25;
border: navajowhite;
padding: 10px 50px;
border-radius: 5px;
color: white;
text-transform: uppercase;
}

.formInput{
    display: flex;
    justify-content: space-between;
}
.testImg{
    width: 30%;
}

.smallModalVideo{
    width: 130px !important;
    height: 130px;
}
.img{
    height: 100%;
   
    object-fit: cover;
}
.errorMsg{
    font-size: 12px;
}
.cancelParent{
    position: absolute;
    right: 25px;
}
.cancelImg{
    width: 15px;
height: 15px;
align-self: center;
}
.priceContainer{
    border: 1px solid rgba(0,0,0,0.1);
}
.data{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    min-height: 140px;
    overflow: auto;
}
.td{
    padding: 5px 0;
    text-align: center;
    font-size: 12px;
}
.th{
    padding: 6px 0;
}
.productname{
    width: 85%;
    margin: 0;
    padding: 5px 0;
}
.color{
    height: 25px;
width: 25px;
border-radius: 50%;
/* border: 1px solid white; */
margin: 0 7px;
cursor: pointer;
}
.categoryContainer{
    margin-top: 15px;
}
.category{
font-weight: 700;
text-decoration: underline;
}
.mblCancelParent{
    display: none;
}
@media only screen and (max-width:600px) {
    .leftContainer{
        width: 100%;
        padding: 0;
        position: relative;
    }
    .mblCancelContainer{
        position: absolute;
top: 10px;
right: 10px;
background: #f5f5f5;
border-radius: 50%;
width: 20px;
height: 20px;
display: flex;
justify-content: center;
    }
    .modalImgContainer{
        padding: 0;
    }
    .mblCancelParent{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        cursor: pointer;
        display: block;
    }
    .rightContainer{
        width: 100%;
    }
    .cancelContainer{
        display: none;
    }
    .addtocartBtn{
        padding: 10px 20px;
        margin-left: 13px;
    }
    .formInput{
        display: block;
    }
    .input{
        width: 100%;
        margin: 5px 0;
    }
    .reviewUserRatingContainer{
        display: block;
        width: 100%;
    }
    .rightDescription{
        font-size: 11px;
    }
    .overallContainer{
        display: block;
    }
    .offerParentContainer{
        width: 100%;
    }
    .productCount{
        margin-left: 5px;
        width: 70px;
    }
    .addtocartBtn{
        margin-left: 20px;
    }
    .productname{
        font-size: 12px !important;
    }
    
}

@media (min-width:601px) and (max-width:768px) {
    .leftContainer{
        width: 100%;
    }
    .modalImgContainer{
        padding: 15px 25px;
    }
    .rightContainer{
        width: 100%;
    }
    .cancelContainer{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .addtocartBtn{
        padding: 10px 20px;
        margin-left: 13px;
    }
    .formInput{
        display: block;
    }
    .input{
        width: 100%;
        margin: 5px 0;
    }
    .reviewUserRatingContainer{
        display: block;
        width: 100%;
    }
    .rightDescription{
        font-size: 11px;
    }
    .overallContainer{
        display: block;
    }
    .offerParentContainer{
        width: 100%;
    }
}

@media (min-width:769px) and (max-width:992px) {
    /* .leftContainer{
        width: 100%;
    } */
    .modalImgContainer{
        padding: 15px 25px;
    }
    .rightContainer{
        width: 100%;
    }
    .cancelContainer{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .addtocartBtn{
        padding: 10px 20px;
        margin-left: 13px;
    }
    .formInput{
        display: block;
    }
    .input{
        width: 100%;
        margin: 5px 0;
    }
    .reviewUserRatingContainer{
        display: block;
        width: 100%;
    }
    .rightDescription{
        font-size: 11px;
    }
    .offerParentContainer{
        width: 100%;
    }
}