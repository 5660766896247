.bg {
    background-color: #F8F3F0;
    padding: 30px;
    min-height: calc(100vh - 68.5px);
}

/* .title{
    padding: 5px 38px;
} */
.titleContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
}

.del {
    width: 15px;
    cursor: pointer;
    margin-right: 10px;
}

.imgContainer {
    width: 70px;
    height: 65px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    /* margin: 0 5%; */
    display: flex;
    justify-content: center;
}

.img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    align-self: center;
}

.productContainer {
    display: flex;
    /* margin: 15px !important; */
    justify-content: space-evenly;
    padding: 8px 0;
}

.productCount {
    width: 60px;
    background: #f5f5f5;
    border: none;
    height: 33px;
    padding: 0px 8px;
    /* margin-left: 25px; */
    outline: unset;
}

.bodyContainer {
    width: 200px !important;
    overflow: auto;
}

/* .tr{
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
} */
.th {
    padding: 15px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.heading {
    background-color: #f5f5f5;
}

.clearCart {
    border: 1px solid #F27F0C;
    background-color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 45px;
    margin-left: 5%;
}

.clearCart:hover {
    background-color: #F27F0C;
    color: white;
}

.updateCart {
    border: none;
    background-color: #F27F0C;
    color: white;
    font-size: 12px;
    padding: 10px 45px;
    font-weight: bold;
}

.updateCart:hover {
    border: 1px solid #F27F0C;
    background-color: white;
    color: black;
}

.cartBtnContainer {
    /* padding: 0 96px; */
    display: none;
    justify-content: space-between;
    padding: 25px 0;
}

.productDetails {
    width: 100%;
    text-align: left;
}

.font {
    font-size: 14px;
    font-weight: bold;
}

.totalDetailsContainer {
    width: 100%;
}

.hr {
    color: rgba(0, 0, 0, 0.3);
    ;
}

.totalContainer {
    display: flex;
    justify-content: end;
    padding-right: 96px;
}

.checkoutBtn {
    width: 100%;
    /* background-color: #F27F0C; */
    border: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    margin: 15px 0;
    border-radius: 5px;
}

tbody {
    height: unset !important;
}

.mblCartotal {
    width: 25%;
}

.td {
    font-size: 13px;
    padding: 10px 0;
}

.thFirst {
    width: 38%;
}

.cartList {
    width: 15%;
}

.cartProductName {
    width: 25%;
    padding: 15px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.cartlistClear {
    width: 8%;
}

.trFirst {
    margin: 0 5%;
    width: 10px;
}

/* .productTitle{
    margin: 0 5%;
    width: 100%;
} */

.cartBtnLeftContainer {
    width: 40%;
}

.cartBtnRightContainer {
    display: flex;
    justify-content: end;
}


.deleteCheck {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.loaderParent {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.filterParentContainer {
    display: flex;
    padding: 17px 38px;
    flex-wrap: wrap;
}

.categories {
    /* width: 30%; */
    position: relative;
    /* margin-right: 40px; */
    padding: 8px 0;
}

.filterInput {
    height: 37px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0 15px;
    outline: unset;
    width: 100%;
    margin: 5px 0;
}

.searchBtn {
    width: 11%;
    height: 37px;
    border: none;
    background: #ff7b26;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.resetBtn {
    width: 11%;
    height: 37px;
    border: 1px solid #ff7b26;
    background: white;
    border-radius: 5px;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.searchBtnContainer {
    /* width: 30%; */
    align-self: center;
    display: flex;
    justify-content: end;
}

.minAmtError {
    color: red;
    font-size: 20px;
    text-align: end;
    padding: 10px 60px;
    font-weight: 600;
}

.deleteConfirmationBtnContainer {
    width: 100%;
    float: left;
    margin-top: 15px;
    display: flex;
    justify-content: end;
}

.deleteConfirmationOkBtn {
    width: 50%;
    float: left;
    background-color: #ff7b26;
    border: none;
    padding: 10px 0;
    color: white;
    font-weight: bold !important;
}

.deleteConfirmationCancelBtn {
    width: 50%;
    float: left;
    background-color: white;
    border: none;
    padding: 10px 0;
    color: black;
    font-weight: bold !important;
}

.btnParent {
    width: 60%;
}

.searchIcon {
    width: 30px;
    height: 30px;
    align-self: center;
    cursor: pointer;
}

.cartList {
    padding: 15px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.complaintsImg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 5px;
}

.changeStatusOrder {
    width: 17px;
    height: 17px;
    cursor: pointer;
    margin-left: 5px;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.searchImg {
    width: 20px;
    height: 20px;
    align-self: center;
    cursor: pointer;
    margin-right: 15px;
}

.filterParentContainer {
    /* display: flex;
    justify-content: space-between; */
    padding: 17px 38px;
}

.categories {
    width: 20%;
    position: relative;
}

.filterInput {
    height: 37px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0 15px;
    outline: unset;
    width: 100%;
    margin: 5px 0;
}

.CsvBtn {
    padding: 5px;
    height: 37px;
    border: 1px solid #ff7b26;
    background: white;
    border-radius: 5px;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.download {
    width: 20px;
    height: 20px;
    margin-left: 6px;
}

.searchBtn {
    width: 150px;
    height: 37px;
    border: none;
    background: #ff7b26;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    text-transform: uppercase;

}

.resetBtn {
    width: 150px;
    height: 37px;
    border: 1px solid #ff7b26;
    background: white;
    border-radius: 5px;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    text-transform: uppercase;

}

@media only screen and (max-width:450px) {
    .mblCartotal {
        width: 100% !important;
    }

    .totalContainer {
        padding-right: 0 !important;
        padding: 0 60px !important;
    }

    .cartBtnContainer {
        display: flex;
        padding: 0 !important;
    }

    .table {
        width: 800px !important;
    }

    .tableParentContainer {
        overflow-x: auto !important;
    }

    .productDetails {
        width: 209px !important;
        font-size: 12px;
        margin-left: 15px;
    }

    .imgContainer {
        margin-left: 15px;
    }

    .td {
        font-size: 12px;
    }

    .th {
        font-size: 13px;
    }

    .cartProductName {
        font-size: 13px;
    }

    .cartList {
        font-size: 13px;
    }

    .bg {
        padding: 30px;
    }

    .resetBtn {
        width: 45%;
    }

    .searchBtn {
        width: 45%;
    }

    .filterParentContainer {
        padding: 17px 13px;
    }
}

@media (min-width:451px) and (max-width:768px) {
    .mblCartotal {
        width: 50% !important;
        padding: 0 45px;
    }

    .totalContainer {
        padding-right: 0 !important;
    }

    .table {
        width: 800px !important;
    }

    .tableParentContainer {
        overflow-x: auto !important;
    }

    .cartBtnContainer {
        display: flex;
        padding: 0 !important;
    }
}

@media (min-width:769px) and (max-width:992px) {}