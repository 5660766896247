.bg {
  padding: 20px;
  min-height: calc(100vh - 68.5px);
}
h1.title {
  padding: 5px 15px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.imgContainer {
  width: 50px;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 5px 10px 5px 0;
  border-radius: 5px;
  float: left;
  margin-right: 5px;
}
.img {
  width: 50px;
  height: 45px;
  border-radius: 5px;
  object-fit: contain;
  align-self: center;
}
.productCount {
  width: 80px;
  background: #f5f5f5;
  border: none;
  height: 35px;
  padding: 0px 8px;
  outline: unset;
  font-weight: 600;
}
.bodyContainer {
  width: 200px !important;
  overflow: auto;
}
.mobileCart .item {
  border: 1px solid #ededed;
  padding: 10px;
  border-radius: 5px;
  min-height: 105px;
  margin-bottom: 15px;
}
.mobileCart .item img {
  float: left;
  margin-right: 5px;
}

.mobileCart .item .clsicon {
  float: right;
  margin-right: 0;
  background: #fff;
  border-radius: 10px;
}

.mobileCart .item p.title {
  margin-bottom: 5px;
  font-size: 13px;
  max-height: 35px;
  overflow: hidden;
}
.unitColor{
  display: flex;
  justify-content: space-between;
}

.mobileCart .item p.title a {
  text-decoration: none;
  color: #000;
}

.mobileCart .item p.price {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 14px;
  max-height: 35px;
  overflow: hidden;
  float: right;
  font-weight: 700;
}

.mobileCart .item .quantity {
  margin-bottom: 5px;
  margin-right: 10px;
  font-size: 13px;
  background: #efefef;
  float: left;
}

.heading {
  background-color: #f5f5f5;
}
.del {
  width: 15px;
  cursor: pointer;
}
.clearCart {
  border: 1px solid #f27f0c;
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 45px;
  margin-left: 5%;
}
.clearCart:hover {
  background-color: #f27f0c;
  color: white;
}
.updateCart {
  border: none;
  background-color: #f27f0c;
  color: white;
  font-size: 12px;
  padding: 10px 45px;
  font-weight: 600;
  text-transform: uppercase;
}
.updateCart:hover {
  border: 1px solid #f27f0c;
  background-color: white;
  color: black;
}
.cartBtnContainer {
  /* padding: 0 96px; */
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}
.font {
  font-size: 17px;
  font-weight: 600;
}
.checkoutBtn {
  width: 100%;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 0;
  margin: 15px 0;
  border-radius: 5px;
  text-transform: uppercase;
}
.cartBtnRightContainer {
  display: flex;
  justify-content: end;
}
.deleteCheck {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filterParentContainer {
  display: flex;
  padding: 17px 38px;
  flex-wrap: wrap;
}
.categories {
  /* width: 30%; */
  position: relative;
  /* margin-right: 40px; */
  padding: 8px 0;
}
.filterInput {
  height: 46px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0 15px;
  outline: unset;
  width: 100%;
  margin: 5px 0;
}
.searchBtn {
  width: 20%;
  height: 46px;
  border: none;
  background: #ff7b26;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.resetBtn {
  width: 20%;
  height: 46px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.searchBtnContainer {
  align-self: center;
  display: flex;
  justify-content: end;
}
.overallcart {
  float: left;
  width: 100%;
}
.leftTable {
  width: 100%;
  float: left;
}
.rightCart {
  float: right;
  max-width: 320px;
  width: 100%;
  margin-top: 20px;
}
.priceDiv {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  margin-bottom: 5px;
}
