.parentContainer{
    background-color: #F8F3F0;
    padding: 0 !important;
}
.childContainerParent{
    padding: 18px;
    min-height: calc(100vh - 68.5px);
}
.childContainer{
    background-color: white;
    padding: 0 !important;
}
.imgContainer{
    width: 60px;
    height: 60px;
}
.th{
    padding: 10px 0;
}
.addText{
    color: white;
    align-self: center;
}

thead{
    background: #f5f5f5;
}

.paginationContainer{
    display: flex;
    justify-content: end;
}

.img{
    width: 100%;
    height: 100%;
}
.imgTd{
    display: flex;
    justify-content: center;
}
/* .productName{
    font-size: 12px;
    letter-spacing: 1px;
} */
.productNameTh{
    width: 20%;
    padding: 5px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}
.productCount{
    width: 60px;
    background: #f5f5f5;
    border: none;
    height: 33px;
    padding: 0px 8px;
    margin-left: 25px;
    outline: unset;
}
.searchParentContainer{
    display: flex;
    justify-content: space-between;
    padding: 15px 22px;
    /* padding-bottom: 0; */
}

.searchImg{
    width: 25px;
    height: 25px;
}
.readmore{
    cursor: pointer;
}
.tableHead{
    padding: 8px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}
.paymentMsg{
    font-size: 20px;
font-weight: bold;
text-align: center;
padding: 8px 0;
}
.actionTableHead{
    padding: 8px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
    width: 7%;
}
.td {
    padding: 10px 0;
}
.filterParentContainer{
    /* display: flex; */
    padding: 17px 38px;
    /* flex-wrap: wrap;
    padding-top: 0; */
}
/* .categories{
    width: 30%;
    position: relative;
    padding: 8px 0;
} */
.filterInput{
    height: 37px;
border: 1px solid rgba(0,0,0,0.1);
border-radius: 5px;
padding: 0 15px;
outline: unset;
width: 100%;
margin: 5px 0;
background-color: white;
}
.price{
    display: flex;
justify-content: center;
height: 39.5px;
}
.priceAmt{
    width: 75px;
text-align: start;
align-self: center;
}
.priceSymbol{
    text-align: right;
padding-right: 5px;
/* width: 25%; */
align-self: center;
}
.newBillBtn{
    width: 40%;
height: 37px;
border: none;
background: #ff7b26;
border-radius: 5px;
color: white;
font-size: 13px;
font-weight: bold;
text-align: center;
/* margin-bottom: 5px; */
text-transform: uppercase;
}
.CsvBtn{
    width: 40%;
height: 37px;
border: 1px solid #ff7b26;
background: white;
border-radius: 5px;
color: #ff7b26;
font-size: 13px;
font-weight: bold;
text-align: center;
/* margin-bottom: 5px; */
text-transform: uppercase;
}
.newBillBtnContainer{
    width: 340px;
    display: flex;
    justify-content: space-between;
}
.searchBtn{
    width: 35%;
height: 37px;
border: none;
background: #ff7b26;
border-radius: 5px;
color: white;
font-size: 13px;
font-weight: bold;
text-align: center;
margin-bottom: 5px;
text-transform: uppercase;
}
.resetBtn{
    width: 35%;
height: 37px;
border: none;
border: 1px solid #ff7b26;
background-color: white;
border-radius: 5px;
color: #ff7b26;
font-size: 13px;
font-weight: bold;
text-align: center;
margin-right: 36px;
text-transform: uppercase;
}
.download{
    width: 20px;
height: 20px;
margin-left: 6px;
}
.searchBtnContainer{
    /* width: 30%; */
align-self: end;
display: flex;
justify-content: end;
/* margin-bottom: 7px; */
}
.deleteConfirmationBtnContainer{
    width: 100%;
    float: left;
    margin-top: 15px;
    display: flex;
justify-content: end;
}
.btnParent{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.deleteConfirmationOkBtn{
    width: 47%;
    float: left;
    background-color: #ff7b26;
    border: none;
    padding: 10px 0;
color: white;
font-weight: bold !important;
border-radius: 5px;
}
.deleteConfirmationCancelBtn{
    width: 47%;
    float: left;
    background-color: white;
    border: none;
    padding: 10px 0;
color: black;
font-weight: bold !important;
border-radius: 5px;
}
.productName{
    width: 25%;
}
.viewImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.viewImgDownload{
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 6px;
}
.actionContainer{
    /* display: flex; */
    justify-content: start;
padding-left: 10px;
}
.loaderParent{
    width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .searchIcon{
    width: 20px;
    height: 20px;
    align-self:center;
    cursor: pointer;
  }
@media only screen and (max-width:450px) {
   .TableChildContainer{
       width: 930px !important;
   }
   .tableParentContainer{
       overflow-x: auto !important;
   }
   .actionsHead{
       width: 17%;
       font-size: 12px;
   }
   .tableHead{
       font-size: 12px;
   }
   .td{
    font-size: 11px;
   }
   .IconChildContainer{
       width: 20px;
       height: 20px;
   }
   .actionsIcon{
       width: 12px;
       height: 12px;
   }
   .filterParentContainer{
    display: block;
    padding: 15px;
    padding-top: 0;
}
.categories{
    width: 100%;
}
.searchBtnContainer{
    width: 100%;
    justify-content: center;
}
.childContainerParent{
    padding: 50px 13px;
}
.newBillBtnContainer{
    width: 100%;
    padding: 12px 0;
}
.searchParentContainer{
    padding: 15px 20px;
    display: block;
    padding-bottom: 0;
}
.CsvBtn{
    margin-bottom: 0;
    height: 36px;
    font-size: 11px;
}
.newBillBtn{
    margin-bottom: 0;
    height: 36px;
    font-size: 11px;
}
.resetBtn{
    margin-bottom: 0;
    height: 36px;
    font-size: 11px;
    margin-right: 10px;
}
.searchBtn{
    margin-bottom: 0;
    height: 36px;
    font-size: 11px;
}
.searchBtnContainer{
    padding: 15px 0;
    justify-content: end;
}
.searchIcon{
    width: 20px;
    height: 20px;
}
.productNameTh{
    font-size: 14px;
}
  }

  @media (min-width:451px) and (max-width:768px) {
    .TableChildContainer{
        width: 800px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .actionsHead{
        width: 17%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        display: flex;
        justify-content: space-between;
    }
    .categories{
        width: 48%;
        margin: 0;
    }
    .searchBtnContainer{
        width: 50%;
    }
    .newBillBtnContainer{
        width: 300px;
    }
    .searchParentContainer{
        padding: 15px 20px;
    }
    .CsvBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
    .newBillBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
    .searchBtnContainer{
        margin-bottom: 0;
    }
    .resetBtn{
        width: 50%;
        margin-right: 10px;
    }
    .searchBtn{
        width: 50%;
    }
  }

  @media (min-width:769px) and (max-width:992px) {
   
    .actionsHead{
        width: 17%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        display: flex;
        justify-content: space-between;
    }
    .categories{
        width: 30%;
        margin: 0;
    }
    .searchBtnContainer{
        width: 30%;
    }
    .newBillBtnContainer{
        width: 40%;
    }
    .searchParentContainer{
        padding: 15px 20px;
    }
    .CsvBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
    .newBillBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
  }

  @media (min-width:993px) and (max-width:1140px) {
    .actionsHead{
        width: 17%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        display: flex;
        justify-content: space-between;
    }
    .categories{
        width: 30%;
        margin: 0;
    }
    .searchBtnContainer{
        width: 30%;
    }
    .newBillBtnContainer{
        width: 40%;
    }
    .searchParentContainer{
        padding: 15px 20px;
    }
    .CsvBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
    .newBillBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
  }

  @media (min-width:1141px) and (max-width:1200px) {
    .actionsHead{
        width: 13%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        display: flex;
        justify-content: space-between;
    }
    .categories{
        width: 30%;
        margin: 0;
    }
    .searchBtnContainer{
        width: 30%;
    }
    .newBillBtnContainer{
        width: 40%;
    }
    .searchParentContainer{
        padding: 15px 20px;
    }
    .CsvBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
    .newBillBtn{
        margin-bottom: 0;
        height: 36px;
        font-size: 11px;
    }
  }