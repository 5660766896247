.LeftContainer{
    width: calc(100% - 720px);
    float: left;
    padding: 30px 25px;
}
.RightContainer{
    width: 420px;
    float: left;
    position: sticky;
    top: 0;
}
.FilterContainer{
    width: 300px;
    float: left;
    position: sticky;
    top: 0;
}
.searchOverall{
    display: flex;
    justify-content: space-between;
}
.paginationContainer{
    justify-content: center;
}
.bg {
    background-color: #F8F3F0;
    /* padding: 25px 45px; */
    position: relative;
    width: 100%;
    float: left;
    padding: 0 !important;

}
/* .searchContainer{
    width: 60%;
} */
.searchInput{
    border: none;
    padding: 10px 25px;
    /* width: 48%; */
    outline: unset;
    font-size: 16px;
    height: 47px;
    border-radius: 5px;
    align-self: center;
}
.dropdownContainer{
    margin-right: 25px;
}
.searchBtn{
    background: #FF7C25;
border: none;
color: white;
font-size: 13px;
font-weight: bold;
padding: 10px 45px;
border-radius: 5px;
height: 47px;
align-self: center;
width: 15% !important;
text-transform: uppercase;
}
.settingsContainer{
    width: 60px;
height: 50px;
display: flex;
justify-content: center;
background-color: white;
border-radius: 5px;
cursor: pointer;
display: none;
}
.settingsImg{
    width: 25px;
}
.searchParentContainer{
    padding: 25px 0px;
    width: 70%;
}

.dropdownBtn{
    box-shadow: unset !important;
    font-size: 13px !important;
    font-weight: bold !important;
    width: 10% !important;
}
/* .btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: unset !important;
  }
  .btn-success {
    color: #000 !important;
    background-color: white !important;
    border-color: unset !important;
    border: none !important;
  }

  .customBtn .dropdown-toggle::after{
    display: none !important;
  } */
  dropdown .caret{
      display: none !important;
  }
  .categoriesListContainer{
    display: flex;
    flex-wrap: wrap;
    padding:0 0 25px 0;
    width: 100%;
  }
  .dropdownParent{
      padding: 10px 0;
      position: relative;
      width: 100%;
  }
  .selectOptionContainer{
    width: 200px;
    background-color: white;
    padding: 15px 25px;
    text-align: center;
    border-radius: 12px;
    margin-right: 25px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
  }
  /* .optionsParent{
    position: absolute;
    top: 95px;
    background: white;
    border-radius: 8px;
    max-height: 300px;
  }
  .ulStyle{
      list-style: disc;
      padding: 5px 25px;
      margin: 0;
  } */
  .notFoundImgContainer{
    display: flex;
    justify-content: center;
    height: 100%;
      }
      .notFoundImg{
          align-self: center;
          width: 250px;
          margin-top: 5%;
      }
.mblcart{
    display: none;
}
.mblRightContainer{
    display: none;
}
.notification{
    background-color: #ff7b26;
    min-width: 17px;
    position: absolute;
    top: 3px;
    border-radius: 50%;
    min-height: 17px;
    display: flex;
    justify-content: center;
    right: 6px;
}
.badge{
    font-size: 12px;
    align-self: center;
    color: white;
    font-weight: bold;
}
.switchChildContainer{
    display: flex;
/* width: 35%;
justify-content: space-between; */
}
.switchTitle{
    align-self: center;
    font-size: 13px;
    font-weight: bold;
    margin-right: 15px;
}
.SwitchParent{
    display: flex;
    justify-content: end;
}
.brandFilter{
    width: 25% !important;
}
  @media only screen and (max-width:450px) {
      .dropdownParent{
          overflow-x: auto;
      }
      .categoriesListContainer{
          justify-content: center;
          display: flex;
      }
      .searchBtn{
          height: unset;
          padding: 10px 10px;
      }
      .searchInput{
          height: unset;
          width: 100%;
      }
      .settingsContainer{
          width: 35px;
          height: 31px;
          align-self: center;
      }
      .settingsImg{
          width: 15px;
      }
      .RightContainer{
          display: none;
      }
      .FilterContainer{
        display: none;
      }
      .LeftContainer{
          width: 100%;
      }
      .mblcart{
        width: 45px;
        position: fixed;
        height: 45px;
        border-radius: 50%;
        background-color: black;
        display: flex;
        justify-content: center;
        right: 15px;
        bottom: 15px;
        cursor: pointer;
        z-index: 1;
      }
      .cartImg{
        width: 25px;
        height: 25px;
        align-self: center;
        
      }
      .mblRightContainer{
        width: 100%;
        float: left;
        position: absolute;
        top: 0;
        display: block;
      }
      .brandFilter{
          width: 100% !important;
          padding: 0 !important;
          margin: 5px 0;
      }
      .searchBtn{
        width: 50% !important;
        margin-bottom: 15px;
      }
      
  }

  @media (min-width:451px) and (max-width:768px) {
    .dropdownParent{
        overflow-x: auto;
    }
    .categoriesListContainer{
        justify-content: center;
        display: flex;
    }
    .searchBtn{
        height: unset;
        padding: 10px 10px;
    }
    .searchInput{
        height: unset;
        width: 65%;
    }
    .settingsContainer{
        width: 35px;
        height: 31px;
        align-self: center;
    }
    .settingsImg{
        width: 15px;
    }
    .RightContainer{
        display: none;
    }
    .FilterContainer{
        display: none;
    }
    .LeftContainer{
        width: 100%;
    }
    .mblcart{
      width: 45px;
      position: fixed;
      height: 45px;
      border-radius: 50%;
      background-color: black;
      display: flex;
      justify-content: center;
      right: 15px;
      bottom: 15px;
      cursor: pointer;
      z-index: 1; 
      box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
    }
    .cartImg{
      width: 25px;
      height: 25px;
      align-self: center;
      
    }
    .mblRightContainer{
      width: 100%;
      float: left;
      position: absolute;
      top: 0;
      display: block;
    }
  }

  @media (min-width:769px) and (max-width:992px) {
    .searchInput{
        width: 60%;
    }
    .dropdownParent{
      overflow-x: auto;
  }
  .categoriesListContainer{
      justify-content: space-around;
  }
  }

  @media (min-width:993px) and (max-width:1140px) {
      
  }

  @media (min-width:1141px) and (max-width:1200px) {
      
  }