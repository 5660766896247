.parentContainer {
  background-color: #f8f3f0;
  padding: 0 !important;
}
.childContainerParent {
  padding: 30px;
  min-height: calc(100vh - 68.5px);
}
.childContainer {
  background-color: white;
  padding: 0 !important;
}
.imgContainer {
  width: 60px;
  height: 60px;
}
.addText {
  color: white;
  align-self: center;
}

thead {
  background: #f5f5f5;
}

.paginationContainer {
  float: right;
}

.img {
  width: 100%;
  height: 100%;
}
.imgTd {
  display: flex;
  justify-content: center;
}
.productName {
  font-size: 12px;
  letter-spacing: 1px;
}
.productNameTh {
  width: 30%;
}
.productCount {
  width: 60px;
  background: #f5f5f5;
  border: none;
  height: 33px;
  padding: 0px 8px;
  margin-left: 25px;
  outline: unset;
}

.searchParentContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
  padding-bottom: 0;
}
.searchInputContainer {
  align-self: end;
}
.searchInput {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  border-radius: 5px;
  padding: 5px 15px;
  outline: unset;
  font-size: 14px;
}
.searchBtn {
  width: 47%;
  height: 40px;
  border: none;
  background: #ff7b26;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.resetBtn {
  width: 47%;
  height: 40px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-weight: bold;
}
.btnContainer {
  display: flex;
  align-self: end;
  justify-content: space-between;
}
.searchImg {
  width: 25px;
  height: 25px;
}
.readmore {
  cursor: pointer;
}
.IconChildContainer {
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.actionsIcon {
  width: 15px;
  height: 15px;
  align-self: center;
  cursor: pointer;
}
.action {
  display: flex;
  justify-content: center;
}
.EditPrice {
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: unset;
  width: 50px;
  padding: 5px 10px;
  text-align: center;
}
.date {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  outline: unset;
  cursor: pointer;
  border-radius: 5px;
}
.select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}
.submitBtn {
  background-color: #ff7b26;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 25px;
  border-radius: 5px;
  height: 46px;
}
.filterContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 25px 35px;
}
.filterChildContainer {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.filterHeading {
  display: flex;
  flex-direction: column;
}
.heading {
  font-size: 13px;
  font-weight: bold;
}
.totalContainer {
  background-color: #f5f5f5;
}
.total {
  padding: 5px 0;
}
.CsvBtn {
  width: 70%;
  height: 37px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.download {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}
.price {
  display: flex;
  justify-content: center;
  height: 51.5px;
  align-items: center;
}
.priceAmt {
  width: 75px;
  text-align: start;
  align-self: center;
}
.priceSymbol {
  text-align: right;
  padding-right: 5px;
  width: 25%;
  align-self: center;
}
.searchIcon {
  width: 20px;
  height: 20px;
  align-self: center;
  cursor: pointer;
}
.searchContainer {
  width: 200px;
  display: flex;
  justify-content: space-around;
}
.MblCsvBtn {
  display: none;
}
.tableHead {
  font-size: 16px;
  font-weight: 600;
}
.amountTitle {
  font-size: 14px;
  font-weight: 600;
}
.amount {
  font-size: 14px;
  font-weight: bold;
  color: #f77a26;
}
.amountChildContainer {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  padding: 5px;
  border-radius: 6px;
}
.amountParentContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
}
@media only screen and (max-width: 450px) {
  .TableChildContainer {
    width: 800px !important;
  }
  .tableParentContainer {
    overflow-x: auto !important;
  }
  .actionsHead {
    width: 17%;
    font-size: 12px;
  }
  .tableHead {
    font-size: 15px;
    font-weight: 500;
  }
  .td {
    font-size: 11px;
  }
  .IconChildContainer {
    width: 20px;
    height: 20px;
  }
  .actionsIcon {
    width: 12px;
    height: 12px;
  }
  .filterContainer {
    display: block;
    padding: 25px 12px;
  }
  .filterChildContainer {
    width: 100%;
    display: block;
  }
  .select {
    width: 100%;
  }
  .searchInput {
    height: 42px;
  }
  .searchInputContainer {
    width: 100%;
    padding: 15px 0;
  }
  .searchBtn {
    height: 42px;
  }
  .filterHeading {
    padding: 5px 0;
    width: 100%;
  }
  .childContainerParent {
    padding: 15px;
  }
  .date {
    width: 100%;
    background-color: white;
  }
  .searchParentContainer {
    padding: 10px 13px;
  }
  .searchIcon {
    width: 20px;
    height: 20px;
  }
  .searchContainer {
    width: 25%;
    justify-content: space-around;
  }
  .title {
    font-size: 15px !important;
  }
  .MblCsvBtn {
    display: block;
    width: 35%;
    height: 25px;
    border: 1px solid #ff7b26;
    background: white;
    border-radius: 5px;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  .download {
    margin: 0;
    width: 15px;
    height: 15px;
  }
  .CsvBtn {
    display: none;
  }
}

@media (max-width: 1140px) {
  .AmountResponsiveContainer {
    overflow-x: auto;
  }
  .amountParentContainer {
    width: 1150px;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .TableChildContainer {
    width: 800px !important;
  }
  .tableParentContainer {
    overflow-x: auto !important;
  }
  .actionsHead {
    width: 17%;
    font-size: 15px;
  }
  .tableHead {
    font-size: 15px;
  }
  .td {
    font-size: 13px;
  }
  .IconChildContainer {
    width: 25px;
    height: 25px;
  }
  .actionsIcon {
    width: 14px;
    height: 14px;
  }
  .filterContainer {
    justify-content: space-between;
    padding: 25px 10px;
  }
  .filterChildContainer {
    width: 100%;
  }
  .searchParentContainer {
    padding: 15px 19px;
  }
  .searchInputContainer {
    display: flex;
  }
  .searchInput {
    height: 42px;
    align-self: end;
  }
  .searchBtn {
    height: 42px;
    align-self: end;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .actionsHead {
    width: 17%;
    font-size: 15px;
  }
  .tableHead {
    font-size: 15px;
  }
  .td {
    font-size: 13px;
  }
  .IconChildContainer {
    width: 25px;
    height: 25px;
  }
  .actionsIcon {
    width: 14px;
    height: 14px;
  }
  .filterChildContainer {
    width: 80%;
  }
  .searchParentContainer {
    padding: 15px 19px;
  }
  .searchInputContainer {
    display: flex;
  }
  .searchInput {
    height: 42px;
    align-self: end;
  }
  .searchBtn {
    height: 42px;
    align-self: end;
  }
}

@media (min-width: 993px) and (max-width: 1140px) {
  .actionsHead {
    width: 17%;
    font-size: 15px;
  }
  .tableHead {
    font-size: 15px;
  }
  .td {
    font-size: 13px;
  }
  .IconChildContainer {
    width: 25px;
    height: 25px;
  }
  .actionsIcon {
    width: 14px;
    height: 14px;
  }
  .filterChildContainer {
    width: 80%;
  }
  .searchParentContainer {
    padding: 15px 19px;
  }
  .searchInputContainer {
    display: flex;
  }
  .searchInput {
    height: 42px;
    align-self: end;
  }
  .searchBtn {
    height: 42px;
    align-self: end;
  }
}

@media (min-width: 1141px) and (max-width: 1200px) {
  .actionsHead {
    width: 13%;
    font-size: 15px;
  }
  .tableHead {
    font-size: 15px;
  }
  .td {
    font-size: 13px;
  }
  .IconChildContainer {
    width: 25px;
    height: 25px;
  }
  .actionsIcon {
    width: 14px;
    height: 14px;
  }
  .filterChildContainer {
    width: 80%;
  }
  .searchParentContainer {
    padding: 15px 19px;
  }
  .searchInputContainer {
    display: flex;
  }
  .searchInput {
    height: 42px;
    align-self: end;
  }
  .searchBtn {
    height: 42px;
    align-self: end;
  }
}
