.overallContainer{
    background-color: white;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
padding: 8px 15px;
margin-bottom: 15px;
}
.img{
    width: 25px;
    height: 20px;
    align-self: center;
}
.date{
    color: rgba(0,0,0,0.6);
    margin-bottom: 0;
    font-size: 12px;
    text-align: end;
padding: 5px 0;
}
.font{
    font-size: 12px;
    font-weight: bold;
}

.dateContainer{
    text-align: center;
    align-self: center;
}
.amt{
    width: 70%;
    float: left;
    text-align: end;
    font-size: 14px;
}
.imgContainer{
    width: 30%;
    float: left;
    display: flex;
    justify-content: space-between;
    height: 44px;
}

@media only screen and (max-width:450px) {
    .amt{
        width: 65%;
    }
    .imgContainer{
        width: 35%;
    }
    .overallContainer{
        border-radius: 12px;
    }
}

@media (min-width:451px) and (max-width:768px) {
    .amt{
        width: 85%;
    }
    .imgContainer{
        width: 15%;
    }
}

@media (min-width:769px) and (max-width:992px) {
    
}

@media (min-width:993px) and (max-width:1140px) {
    
}

@media (min-width:1141px) and (max-width:1200px) {
    
}