.parentContainer {
  background-color: #000;
}

.logo {
  width: 150px;
  cursor: pointer;
}

.menu {
  width: 35px;
  cursor: pointer;
}

.Mblmenu {
  width: 30px;
  cursor: pointer;
  display: none;
}

.logoContainer {
  /* width: 150px;  */
  margin-left: 15px;
  display: flex;
  justify-content: start;
}

.link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin: 0 20px;
}

.link:hover {
  color: #ff7b26;
}

.linkContainer {
  width: 72%;
  align-self: center;
  display: flex;
  justify-content: center;
}

.LogoImg {
  width: 20px;
  cursor: pointer;
  align-self: center;
}

.wishlistContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.notificationIcon {
  width: 33px;
  height: 30px;
}

.wishlistNotification {
  background-color: #ff7b26;
  min-width: 17px;
  position: absolute;
  top: -7px;
  border-radius: 50%;
  min-height: 17px;
  display: flex;
  justify-content: center;
  right: -6px;
}

.wishlistLogoImg {
  width: 25px;
  cursor: pointer;
  align-self: center;
}

.languageLogo {
  width: 30px;
  height: 33px;
  align-self: center;
}

.LogoImg2 {
  width: 28px;
  align-self: center;
  cursor: pointer;
}

.mblLogoImg {
  width: 35px;
  cursor: pointer;
  height: 35px;
  object-fit: cover;
  border: 1px solid white;
  border-radius: 50%;
  align-self: center;
  display: none;
}

.profile {
  background-color: black !important;
  border: none !important;
  box-shadow: unset !important;
  padding: 0 !important;
}

.profile::after {
  display: none !important;
}

.LogoImgContainer {
  width: 216px;
  /* gap: 10px; */
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  /* margin-right: 10px; */
}

.overallContainer {
  padding: 8px 0;
  display: flex;
  justify-content: start;
}

.customerOverallContainer {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}

.notification {
  background-color: #ff7b26;
  min-width: 17px;
  position: absolute;
  top: -10px;
  border-radius: 50%;
  min-height: 17px;
  display: flex;
  justify-content: center;
  right: -9px;
}

.parentNotification {
  position: relative;
  /* width: 25px; */
  align-self: center;
}

.badge {
  font-size: 12px;
  align-self: center;
  color: white;
  font-weight: bold;
}

.userOptionLogo {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.userOptions {
  font-size: 14px;
  font-weight: bold;
}

.menuContainer {
  display: none;
}

@media only screen and (max-width: 450px) {
  .LogoImgContainer {
    width: 70px;
    justify-content: space-between;
  }

  .linkContainer {
    display: none;
  }

  .LogoImg2 {
    display: none;
  }

  .mblLogoImg {
    display: block;
  }

  .logoContainer {
    /* width: 85% !important; */
    justify-content: end !important;
    /* padding-right: 20px; */
  }

  .link {
    color: white !important;
    display: flex !important;
    padding: 6px 15px !important;
    border-radius: 5px !important;
    border-bottom: 1px solid #ffffff36;
    border-radius: 10px !important;
  }

  .link:hover {
    color: #ff7b26;
  }

  .overallContainer {
    justify-content: center !important;
  }

  .Mblmenu {
    display: block;
  }

  .menu {
    display: none;
  }

  .menuContainer {
    align-self: center;
    padding-left: 10px;
    display: block;
  }

  .menuOptions {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .mblMenuContainer {
    padding: 10px 0;
  }

  .mblOverallParentContainer {
    background-color: black !important;
  }

  .mblHeader {
    display: flex !important;
    justify-content: center !important;
  }

  .mblTitle {
    display: flex !important;
    width: 100% !important;
    justify-content: space-around !important;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .linkContainer {
    display: none;
  }

  .LogoImg2 {
    display: none;
  }

  .logoContainer {
    width: 85% !important;
    justify-content: space-around !important;
  }

  .link {
    color: black !important;
    width: 100% !important;
  }

  .link:hover {
    color: #ff7b26;
  }

  .overallContainer {
    justify-content: center !important;
  }

  .link {
    color: white !important;
    display: flex !important;
    padding: 6px 15px !important;
    border-radius: 5px !important;
    border-bottom: 1px solid #ffffff36;
    border-radius: 10px !important;
    width: 95% !important;
  }

  .link:hover {
    color: #ff7b26;
  }

  .overallContainer {
    justify-content: center !important;
  }

  .Mblmenu {
    display: block;
  }

  .menu {
    display: none;
  }

  .menuContainer {
    align-self: center;
    padding-left: 10px;
  }

  .menuOptions {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .mblMenuContainer {
    padding: 10px 0;
  }

  .mblOverallParentContainer {
    background-color: black !important;
  }

  .mblHeader {
    display: flex !important;
    justify-content: center !important;
  }

  .mblTitle {
    display: flex !important;
    width: 100% !important;
    justify-content: space-around !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .linkContainer {
    width: 60%;
    display: flex;
    justify-content: space-around;
  }
}

.sender {
  color: #ff7b26;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  margin-left: 5px;
}

.senderDate {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 12px;
  margin: 5px;
  color: gray;
}

.sendMessage {
  /* color: white; */
  font-weight: bold;
  /* width: 300px; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-align: left; */
  display: flex;
  justify-content: center;
  margin: 5px;
}

.msgoutline {
  width: fit-content;
  height: fit-content;
  max-height: 450px;
  overflow: auto;
  z-index: 1;
  position: absolute;
  /* display: flex;
  justify-content: end; */
  right: 0;

}

.msgbox {
  box-shadow: 0px 5px 4px rgba(204, 201, 201, 0.1);
  border-radius: 6px;
  background-color: white;
  margin: 10px;
  cursor: pointer;
}