.loginRightContainer {
    width: 50%;
    float: left;
    height: 500px;
    padding: 35px 25px;
}

.loginRightPara {
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    width: 75%;
}

.visibleImg {
    width: 20px;
    height: 20px;
}

.inputContainer {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    margin-top: 5px;
}

.emailInput {
    border: none;
    height: 50px;
    outline: unset;
    padding: 0 15px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.passwordInput {
    border: none;
    height: 50px;
    outline: unset;
    padding: 0 15px;
    width: 93%;
}

.loginBtn {
    width: 45%;
    height: 50px;
    background-color: #FF7C25;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 9px;
}

.forgotPassword {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.forgotpasswordContainer {
    text-align: center;
    margin-top: 15px;
}

.errorMsg {
    font-size: 12px;
    font-weight: bold;
}

.bgContainer {
    height: 100vh;
    padding: 0 !important;
    position: relative;
    display: flex;
    justify-content: center;
}

.bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: #ff7c2529;
}

.loginContainer {
    height: 640px;
    max-width: 950px;
    width: 100%;
    float: left;
    background-color: white;
    position: relative;
    align-self: center;
    display: flex;
}

.loginImg {
    width: 300px;
    height: 105px;
}

.loginLeftContainer {
    width: 50%;
    display: flex;
    background-color: #000;
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.labelName {
    font-size: 15px;
    font-weight: 500;
}

@media (max-width:768px) {

    .loginLeftContainer,
    .loginLeftContainer,
    .loginRightContainer,
    .loginContainer {
        display: block !important;
        width: 100% !important;
    }

    .loginLeftContainer,
    .loginContainer,
    .loginRightContainer {
        height: auto !important;
    }

    .loginLeftContainer {
        padding: 20px !important;
        text-align: center;
        justify-content: center;
    }

    .loginRightContainer {
        padding: 35px 20px;
        margin-left: 0;
    }

    .loginImg {
        width: 260px;
        height: auto !important;
    }
}