.overallParentContainer {
  /* background-color: #f8f3f0; */
  /* height: 100vh; */
  padding: 0 !important;
}

.overallChildContainer {
  background-color: white;
  border-radius: 20px;
  /* min-height: calc(100vh - 68.5px); */
  overflow: hidden;
}

.leftContainer {
  width: 100%;
  float: left;
}

.childParent {
  padding: 15px;
}

.rightContainer {
  width: 40%;
  float: left;
  padding: 10px 50px;
}

.formContainer {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.inputLeftContainer {
  width: 47%;
  float: left;
  padding: 5px 0;
}

.inputRightContainer {
  width: 47%;
  float: left;
  padding: 5px 0;
}

.input {
  border: none;
  outline: unset;
  font-size: 15px;
  width: 100%;
}

.inputContainer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 15px;
  border-radius: 9px;
  margin-top: 2px;
}

.ShowShippingDetail {
  width: 20px;
  height: 20px;
  align-self: center;
}

.shippingLabel {
  color: rgba(0, 0, 0, 0.7);
  align-self: center;
}

.checkboxContainer {
  display: flex;
  padding: 10px 0;
}

.cancelBtn {
  border: 1px solid #f27f0c;
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}

.saveBtn {
  background-color: #f27f0c;
  padding: 10px 30px;
  border: none;
  margin-left: 25px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}

.btnContainer {
  padding: 15px 0;
}

.overallLeftContainer {
  padding: 20px;
  background-color: white;
}

.couponBtn {
  background-color: #ff7c25;
  border: none;
  border-radius: 0 6px 6px 0;
  color: white;
  padding: 10px 15px;
}

.couponInput {
  height: 44px;
  border: none;
  font-size: 12px;
  outline: unset;
}

.couponContainer {
  display: flex;
  padding: 15px 0;
}

.couponChildContainer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
}

.orderSummaryContainer {
  width: 100%;
}

.orderSummaryChildContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
}

.radioBtn {
  width: 20px;
  height: 20px;
  align-self: center;
}

.radioLabel {
  padding-left: 15px;
}

.radioParentContainer {
  display: flex;
  padding: 5px 0;
}

.placeOrderText {
  font-size: 13px;
  font-weight: bold;
}

.conditionText {
  color: #116e9a;
  font-size: 13px;
  padding-top: 15px;
}

.paymentMethodContainer {
  padding: 15px 0;
}

.errMsg {
  font-size: 12px;
  font-weight: bold;
}

.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.optionsParent {
  width: 20%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.selectOptionContainer {
  max-width: 300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.customerBtn {
  background-color: #ff7b26;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 5px;
}

.franchiseBtn {
  background-color: white;
  border: 1px solid #ff7b26;
  color: #ff7b26;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 5px;
}

.nextIcon {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  margin-right: 10px;
}

.nextWhite {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.paymentSec {
  display: flex;
  padding: 2px 0;
  margin-right: 10px;
}

.paymentContainer {
  width: 25%;
}

.paymentLabel {
  padding-left: 2px;
  cursor: pointer;
  align-self: center;
}

.paymentRadioBtn {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.paymentTitle {
  padding: 5px 0;
}

.cancelParent {
  position: absolute;
  right: 10px;
  top: 10px;
}

.cancelImg {
  width: 15px;
  height: 15px;
  align-self: center;
}

.cancelContainer {
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.selectPayment {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
  margin-left: 40px;
  font-size: 14px;
}

.pos {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 15px;
  border-radius: 5px;
  outline: unset;
  font-size: 13px;
}

.paymentLink {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 13px;
  outline: unset;
}

.paymentLinkBtn {
  background: #ff7b26;
  color: white;
  font-size: 13px;
  border: none;
  font-weight: bold;
  padding: 7px 31px;
  border-radius: 5px;
}

.PaymentInputContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.otherPaymentBox {
  display: flex;
}

@media only screen and (max-width: 450px) {
  .formContainer {
    display: block;
  }

  .inputLeftContainer {
    width: 100%;
  }

  .inputRightContainer {
    width: 100%;
  }

  .shippingLabel {
    font-size: 14px;
  }

  .paymentContainer {
    width: 65%;
  }
}