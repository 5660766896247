.overallContainer{
    width: 100%;
    background-color: white;
    padding: 15px 25px;
    border-radius: 12px;
    cursor: pointer;
}
/* .profileContainer{
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 50%;
}
.dropdownContainer{
    width: 30%;
    justify-content: space-between;
}
.paymentMethodContainer{
    padding: 10px 0;
}
.profileContent{
    font-size: 12px;
    align-self: center;
}
.walletProfile{
    width: 50%;
}
.price{
    font-size: 13px;
}
.paymentMethodText{
    font-size: 13px;
} */

.td{
    font-size: 12px;
    width: 50%;
    padding: 8px 0;
}
/* .date{
    text-align: end;
font-size: 13px;
font-weight: bold;
} */
.name{
    font-size: 16px;
    font-weight: bold;
}
.description{
    font-size: 12px;
    padding: 3px 0;
    cursor: pointer;
}
/* .qty{
    font-size: 10px;
font-weight: bold;
text-align: end;
} */
.dateContainer{
    display: flex;
    justify-content: space-between;
}
.amount{
    font-size: 13px;
font-weight: bold;
color: green;
}
.nameContainer{
    display: flex;
justify-content: space-between;
}
.count{
    font-size: 12px;
align-self: center;
font-weight: bold;
}
@media only screen and (max-width:450px) {
    .profileContent{
        font-size: 10px;
    }
    .profileContainer{
        width: 45px;
        height: 45px;
    }
    .price{
        font-size: 10px;
    }
    .walletProfile{
        width: 60%;
    }
    .paymentMethodText{
        font-size: 10px;
        align-self: center;
    }
    .dropdownContainer{
        width: 36%;
    }
}


@media (min-width:451px) and (max-width:768px) {
    
}

@media (min-width:769px) and (max-width:992px) {
    .walletProfile{
        width: 30%;
    }
    .dropdownContainer{
        width: 16%;
    }
    .profileContent{
        font-size: 15px;
    }
    .price{
        font-size: 15px;
    }
    .paymentMethodText{
        font-size: 15px;
    }
}

@media (min-width:923px) and (max-width:1140px) {
    
}

@media (min-width:1141px) and (max-width:1200px) {
    
}