.cancelParent {
  position: absolute;
  right: 11px;
  top: 7px;
  /* background: white;
border-radius: 50%; */
}

.cancelImg {
  width: 15px;
  height: 15px;
  align-self: center;
}

.cancelContainer {
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.inputRadio {
  width: 18px;
  height: 18px;
  align-self: center;
  cursor: pointer;
}

.label {
  /* color: white; */
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin-left: 15px;
  cursor: pointer;
}

.parentFormContainer {
  max-width: 400px;
  margin: auto;
  padding: 8px 0;
}

.childFormContainer {
  padding: 10px;
  display: flex;
}

.btn {
  background: #ff7b26;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 5px;
}

.errMsg {
  font-size: 13px;
  font-weight: bold;
  padding-left: 14px;
}

.paymentRadioBtn {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.paymentTitle {
  padding: 5px 0;
}

.cancelParent {
  position: absolute;
  right: 10px;
  top: 10px;
}

.cancelImg {
  width: 15px;
  height: 15px;
  align-self: center;
}

.cancelContainer {
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.selectPayment {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
  margin-left: 40px;
  font-size: 14px;
}

.pos {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 15px;
  border-radius: 5px;
  outline: unset;
  font-size: 13px;
}

.paymentLink {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 13px;
  outline: unset;
}

.paymentLinkBtn {
  background: #ff7b26;
  color: white;
  font-size: 13px;
  border: none;
  font-weight: bold;
  padding: 7px 31px;
  border-radius: 5px;
}

.PaymentInputContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.paymentSec {
  padding: 10px;
}

.paymentMsg {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 8px 0;
}