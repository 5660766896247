.overallContainer {
    /* background-color: #F8F3F0; */
    /* height: 100vh; */
    padding: 0;
}

.firstContainer {
    /* margin: 0 37px; */
    /* padding: 25px 0; */
}

.firstTitle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    /* background-color: white; */
}

/* .inputContainer{
    width: 24%;
padding-right: 10px;
margin-bottom: 14px;
} */
.profileText {
    font-size: 15px;
    font-weight: 600;
    color: #080808;
}

label,
.label {
    color: #777 !important;
    font-weight: 600 !important;
}

.input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
    border-radius: 5px;
    outline: unset;
    font-size: 15px;
    font-weight: 500;
}

.firstParentContainer {
    width: 100%;
    display: flex;
    /* padding: 15px 30px; */
}

.radioBtnContainer {
    width: 20%;
    display: flex;
    /* flex-direction: column; */
    /* padding: 0 37px; */
}

.radioContainer {
    display: flex;
    padding: 10px 15px;
    /* border: 1px solid rgba(0,0,0,0.1); */
    /* background-color: white; */
}

.radio {
    width: 20px;
    height: 20px;
    align-self: center;
}

.radioParentContainer {
    display: flex;
}

.addressContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* padding: 15px 37px; */
    /* flex-direction: column; */
}

.textarea {
    resize: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 110px;
    outline: unset;
}

.profileImg {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50%;
    object-fit: cover;
    border: .5px solid #ff842e;
}

.cancelImgContainer {
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 2px;
    display: flex;
    justify-content: center;
}

.cancelImg {
    width: 15px;
    height: 15px;
    align-self: center;
}

.imgInput {
    margin: 10px 0;
}

.imgContainer {
    padding: 0 37px;
}

.accountContainer {
    display: flex;
    justify-content: space-around;
    /* background-color: white; */
    padding: 25px 17px;
}

.accInputContainer {
    width: 30%;
    display: flex;
}

.accParentContainer {
    /* padding: 0 37px; */
}

.whiteBg {
    padding: 30px;
    background-color: #fff;
}

.submitBtn {
    background-color: #FF842E;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 60px;
    border-radius: 5px;
    text-transform: capitalize;
}

.btnContainer {
    /* padding: 25px 37px; */
    display: flex;
    justify-content: end;
}

.errorMsg {
    font-size: 12px;
    font-weight: bold;
}

.loaderParent {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* /profile page/ */

.pageWrap {
    padding: 0 36px;
}

.cards {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, -0.8), 0 6px 12px 0 rgba(0, 0, 0, 0.19);
    padding: 20px 30px;
}

.cards:not(:nth-last-child(1)) {
    margin-bottom: 20px;
}

.profileIcon {
    background-color: #fff;
    padding: 10px;
    height: 40px;
    width: 40px;
    position: relative;
    margin-right: 10px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.profileIcon>img {
    width: 100%;
    height: 100%;
    color: #ff7b26 !important;
}

.BankAccountOuter {
    display: flex;
    justify-content: space-between;
}

.addbankDetails {
    background-color: white;
    border: 1px solid #F27F0C;
    border-radius: 6px;
    color: #F27F0C;
    font-size: 17px;
    padding: 10px 46px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 15px;
}

.Deletebtn {
    background-color: #F27F0C;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 17px;
    padding: 10px 46px;
    text-transform: uppercase;
    /* margin-left: 15px; */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}


/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.accText {
    margin-bottom: 20px;
    font-size: 18px !important;
    /* background: white; */
    /* padding-left: 25px; */
    /* padding-top: 15px; */
}

input:invalid {
    animation: shake 300ms;
    border: 1px solid red;
}

@keyframes shake {
    25% {
        transform: translateX(4px);
    }

    50% {
        transform: translateX(-4px);
    }

    75% {
        transform: translateX(4px);
    }
}

@media only screen and (max-width:450px) {
    .firstContainer {
        margin: 0 20px;
    }

    .firstParentContainer {
        display: block;
        padding: 15px 15px;
    }

    /* .inputContainer{
          width: 100%;
      } */
    .radioBtnContainer {
        width: 100%;
    }

    .accountContainer {
        display: block;
    }

    .accInputContainer {
        width: 100%;
    }

    /* .accParentContainer{
          padding: 0 20px;
      } */
    .btnContainer {
        justify-content: center;
    }

    .whiteBg {
        padding: 0px !important;
        background-color: transparent;
    }

    .pageWrap {
        padding: 0 15px;
    }
}

@media (min-width:451px) and (max-width:768px) {
    .firstParentContainer {
        flex-wrap: wrap;
    }

    /* .inputContainer{
          width: 45%;
      } */
    .input {
        margin: 5px 0;
    }

    .radioBtnContainer {
        width: 100%;
    }
}

@media (min-width:769px) and (max-width:992px) {
    .firstParentContainer {
        flex-wrap: wrap;
    }

    /* .inputContainer{
        width: 45%;
    } */
    .input {
        margin: 5px 0;
    }

    .radioBtnContainer {
        width: 100%;
    }
}

@media (min-width:993px) and (max-width:1140px) {
    /* .inputContainer{
          width: 23%;
      } */
}

@media (min-width:1141px) and (max-width:1200px) {}