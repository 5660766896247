.overallParent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
background-color: #F8F3F0;
min-height: calc(100vh - 68.5px);
}
.childContainer{
    width: 50%;
    padding: 50px 0;
}
.overallInput{
    width: 50%;
    margin: auto;
}
.visibleImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.inputContainer{
    width: 100%;
height: 50px;
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
border-radius: 9px;
margin-top: 5px;
background-color: white;
font-size: 13px;
}
.emailInput{
    border: none;
height: 50px;
outline: unset;
padding: 0 15px;
width: 90%;
}
.passwordInput{
    border: none;
height: 50px;
outline: unset;
padding: 0 15px;
width: 93%;
font-size: 15px;
font-weight: 500;
}
.btn{
    width: 50%;
    height: 50px;
    background-color: #FF7C25;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 9px;
    margin: 20px 0; 
    text-transform: uppercase;
    font-size: 16px;
}
.errorMsg{
    font-size: 12px;
    font-weight: bold;
}
.loaderParent{
    width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media only screen and (max-width:450px) {
  .childContainer{
      width: 100%;
  }
  .overallInput{
      width: 90%;
  }
  .passwordInput{
      width: 90%;
  }
}

@media (min-width:451px) and (max-width:768px) {
    .childContainer{
        width: 100%;
    }
    .overallInput{
        width: 60%;
    }
    .passwordInput{
        width: 90%;
    }
}

@media (min-width:769px) and (max-width:992px) {
    .childContainer{
        width: 100%;
    }
    .overallInput{
        width: 50%;
    }
    .passwordInput{
        width: 90%;
    }
}

@media (min-width:993px) and (max-width:1140px) {
    .childContainer{
        width: 100%;
    }
    .overallInput{
        width: 50%;
    }
    .passwordInput{
        width: 90%;
    }
}

@media (min-width:1141px) and (max-width:1200px) {
    .childContainer{
        width: 100%;
    }
    .overallInput{
        width: 50%;
    }
    .passwordInput{
        width: 90%;
    }
}