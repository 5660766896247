.parentDetails{
    width: 100%;
    float: left;
    margin-top: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
padding: 30px;
}
.leftDetails{
    width: 50%;
    float: left;
}
.rightDetails{
    width: 50%;
    float: left;
}
.details{
    font-size: 15px;
    padding: 1px 0 1px 11%;
}
.productName{
    cursor: pointer;

}
.cartProductName{
    width: 40%;
}
.readmore{
    font-size: 13px;
    font-weight: bold;
}
.shippingDetails{
    font-size: 15px;
    padding: 1px 0 1px 15%;
}
.tableParent{
    width: 100%;
float: left;
margin-top: 35px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
padding: 30px;
}
.th{
    padding: 10px 0;
}
.btn{
    background-color: #ff7b26;
    color: white;
    font-size: 13px;
    font-weight: bold;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    text-transform: uppercase;
}
.PreviousBtn{
    background-color: white;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid #ff7b26;
    padding: 10px 15px;
    border-radius: 5px;
    margin-right: 15px;
    text-transform: uppercase;
}
.btnParent{
    width: 100%;
    float: left;
    display: flex;
    justify-content: end;
    margin: 25px 0;
}
.nextIcon{
    width: 20px;
  height: 20px;
  transform: rotate(180deg);;
  margin-right: 10px;
  }
  .nextWhite{
      width: 20px;
  height: 20px;
  margin-left: 10px;
  }
  .price{
    display: flex;
justify-content: center;
height: 44px;
}
.priceAmt{
    width: 75px;
text-align: start;
align-self: center;
}
.priceSymbol{
    text-align: right;
padding-right: 5px;
width: 25%;
align-self: center;
}
.priceSymbol1{
    text-align: right;
padding-right: 5px;
width: 8%;
align-self: center;
}
.swichContainer{
    display: flex;
justify-content: space-between;
padding: 10px 0;
}
.switchChildContainer{
    display: flex;
width: 15%;
justify-content: space-between;
}
.switchTitle{
    font-size: 15px;
align-self: center;
font-weight: 500;
}
.paymentSec{
    display: flex;
    padding: 2px 0;
        }
        .paymentContainer{
            width: 25%;
        }
        .paymentLabel{
            padding-left: 15px;
            cursor: pointer;
        }
        .paymentRadioBtn{
            width: 17px;
    height: 17px;
    cursor: pointer;
        }
        .paymentTitle{
            padding: 5px 0;
        }
@media only screen and (max-width:450px) {
    .parentDetails{
        padding: 15px;
    }
    .leftDetails{
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }
    .rightDetails{
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }
    .details{
        padding: 0;
    }
    .shippingDetails{
        padding: 0;
    }
    .childTable{
        width: 1000px;
    }
    .ParentTable{
        overflow-x: auto;
    }
    .tableParent{
        padding: 10px;
    }
    .swichContainer{
        display: block;
    }
    .switchChildContainer{
        width: 100%;
    }
    .switchTitle{
        width: 82%;
text-align: end;
    }
}
@media (min-width:451px) and (max-width:768px) {
    .childTable{
        width: 1000px;
    }
    .ParentTable{
        overflow-x: auto;
    }
    .tableParent{
        padding: 10px;
    }
    .cartProductName{
        width: 25%;
    }
    .switchChildContainer{
        width: 25%;
    }
   
}
@media (min-width:769px) and (max-width:992px) {
    .childTable{
        width: 900px;
    }
    .ParentTable{
        overflow-x: auto;
    }
    .tableParent{
        padding: 10px;
    }
    .cartProductName{
        width: 25%;
    }
    .switchChildContainer{
        width: 25%;
    }
}
@media (min-width:993px) and (max-width:1140px) {
    .childTable{
        width: 900px;
    }
    .ParentTable{
        overflow-x: auto;
    }
    .tableParent{
        padding: 10px;
    }
    .cartProductName{
        width: 25%;
    }
    .switchChildContainer{
        width: 20%;
    }
}
@media (min-width:1141px) and (max-width:1200px) {
    
}