.img{
    height: 26px;
margin-right: 10px;
}
ul {
    list-style: none;
    padding: 0;
}
li{
    padding: 10px 0;
}
.homeImg{
    max-width: 1200px;
}
.parentContainer{
    display: flex !important;
}
.content{
    width: 50% !important;
}

@media only screen and (max-width:600px) {
    .parentContainer{
        display: unset !important;
    }
    .content{
        width: 100% !important;
    }
    .homeImg{
        max-width: 350px !important;
    }
    
}

@media (min-width:601px) and (max-width:768px) {
    .parentContainer{
        display: unset !important;
    }
    .content{
        width: 100% !important;
    }
    .homeImg{
        max-width: 700px !important;
    }
}