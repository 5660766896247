.overallContainer {
  width: 282px !important;
  background-color: #ffe8d8;
  border: 1px solid #ffe2cd;
  border-radius: 40px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px; */
  margin: 10px 20px 15px 0;
  padding: 10px !important;
  float: left;
  cursor: pointer;
}
.imgContainer {
  width: 100%;
  height: 180px;
  background-color: white;
  border: 1px solid aliceblue;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px -3px;
  position: relative;
  border-radius: 30px;
}
.optionsContainer {
  width: 30px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.02);
  border: 0.613514px solid rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4.90811px;
  margin: 2px 0;
}
.optionsImg {
  width: 18px;
  height: 18px;
  align-self: center;
}
.optionsParentContainer {
  position: absolute;
  right: 10px;
  bottom: 15px;
}
.img {
  max-width: 100%;
  max-height: 170px;
  object-fit: contain;
  align-self: center;
}
.details {
  font-size: 14px;
  text-align: center;
}
.description {
  width: 100%;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  min-height: 42px;
  overflow: hidden;
}
.rate {
  color: #ffb230;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.qty {
  font-size: 12px;
  font-weight: bold;
}
.Productrate {
  color: #ffb230;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-decoration: line-through;
}
.productqty {
  font-size: 12px;
  font-weight: bold;
}
.productqtyContainer {
  display: flex;
  justify-content: space-around;
  padding: 0 60px;
}
.outofstock {
  position: absolute;
  right: 10px;
  top: 8px;
  color: red;
  font-size: 12px;
  font-weight: bold;
}
.popover {
  font-size: 14px;
  font-weight: 400;
  padding: 6px;
}
.priceDisplay {
  text-align: center;
}
.customerNewPrice {
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 500;
}
.oldPrice {
  font-size: 14px;
  font-weight: 700;
  margin-left: 6px;
}
.qtyContainer {
  display: flex;
  justify-content: center;
}
.qty {
  align-self: center;
  margin-left: 6px;
}
@media (min-width: 451px) and (max-width: 768px) {
  .overallContainer {
    width: 40%;
    margin: 0;
    justify-content: center;
    display: flex;
    justify-content: space-around;
  }
  .outofstock {
    position: absolute;
    right: 10px;
    top: 8px;
    color: red;
    font-size: 12px;
    font-weight: bold;
  }
  .customerNewPrice {
    font-size: 17px;
    padding-right: 5px;
    font-weight: bold;
  }
  .priceDisplay {
    float: left;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }
  .oldPrice {
    font-size: 15px;
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 5px;
  }
}
