.leftheading{
    color: white;
    font-size: 27px;
}
.rightheading{
    color: #ff7b26;
}
.btn{
    background-color: #ff7b26;
color: white;
border: none;
padding: 15px 36px;
font-size: 18px;
border-radius: 10px;
}

/* .test {
    margin-top: 25px;
    font-size: 21px;
    text-align: center;
    animation: fadein 5s;
    -moz-animation: fadein 5s; 
    -webkit-animation: fadein 5s; 
    -o-animation: fadein 5s; 
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
} */
.parentContainer{
    display: flex;
}
.leftContainer{
    width: 50%;
    align-self: center;
    text-align: left;
}
.map{
    width: 50%;
}

@media only screen and (max-width:600px) {

    .parentContainer{
        display: block !important;
      }
    .leftContainer{
        width: 100% !important;
        margin-top: 15% !important;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .map{
        width: 100% !important;
        margin-top: 15% !important;
    }
    .leftheading{
        text-align: center;
    }
    .rightheadingMbl{
        text-align: center;
    }
    .thirdTitle{
        text-align: center;
    }
  
}

@media (min-width:601px) and (max-width:768px) {
    .map{
        display: none !important;
    }
    .leftContainer{
        width: 100% !important;
        margin-top: 10% !important;
    }
    .rightheadingMbl{
        font-size: 30px !important;
        text-align: center;
    }
    .leftheading{
        text-align: center;
    }
    .thirdTitle{
        text-align: center;
    }
    .btnContainer{
        display: flex;
        justify-content: center;
    }
}

@media (min-width:769px) and (max-width:992px) {
    
}