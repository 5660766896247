.bg{
    background-color: #F8F3F0;
    padding: 70px 0;
}
.overallBody{
    padding: 0 40px !important;
}
.input{
    border: none;
outline: none;
padding: 10px 15px;
}
.img{
    width: 20px;
}
.searchContainer{
    width: 62%;
background-color: white;
display: flex;
justify-content: space-evenly;
border-radius: 13px;
box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.addMemberContainer{
    width: 30%;
background-color: #F27F0C;
display: flex;
justify-content: space-between;
padding: 7px 15px;
border-radius: 13px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.addMemberImgContainer{
    width: 40px;
height: 40px;
object-fit: contain;
display: flex;
justify-content: center;
background: rgba(255, 255, 255, 0.2);
border-radius: 9px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.addImg{
    width: 23px;
    align-self: center;
}
.addText{
    font-size: 14px;
color: white;
font-weight: bold;
align-self: center;
padding-right: 8px;
}
.btnContainer{
    width: 50%;
float: left;
display: flex;
justify-content: space-between;
}
.titleContainer{
    padding: 35px 0;
    display: flex !important;
    justify-content: space-between !important;
}
.editContainer{
    width: 40px;
height: 40px;
background: rgba(76, 182, 160, 0.15);
border-radius: 50%;
display: flex;
justify-content: center;
}
.editImg{
    width: 19px;
    height: 21px;
    align-self: center;
}
.delContainer{
    width: 40px;
height: 40px;
background: rgba(255, 2, 2, 0.15);
border-radius: 50%;
display: flex;
justify-content: center;
}
.delImg{
    width: 20px;
}
.heading{
    background: rgba(255, 124, 37, 0.1);;
}
.td{
    padding: 35px 0;
    font-size: 12px;
}
.pageSelect{
    width: 75px !important;
border: none !important;
box-shadow: unset !important;
}
.loaderParent{
    width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  }
  .font{
      font-size: 12px !important;
  }
  .thFont{
    font-size: 12px;
  }
 
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .mblAddBtn{
display: none;
  }
 
  @media only screen and (max-width:600px) {
    .btnContainer{
        width: 100% !important;
        justify-content: space-between !important;
        padding: 15px 0;
    }
    .searchContainer{
        width: 84% !important;
    }
    .addText{
        font-size: 12px !important;
    }
    .addMemberContainer{
        display: none;
    }
    .tableChildContainer{
        width: 900px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
        width: 100% !important;
    }
    .rowsFont{
        font-size: 12px !important;
    }
    .pageSelect{
        font-size: 12px !important;
    }
    .mblAddBtn{
        position: absolute;
right: 40px;
background-color: #ff7b26;
z-index: 1;
top: 250px;
width: 10%;
display: flex;
justify-content: center;
padding: 7px 15px;
border-radius: 7px;
cursor: pointer;
    }
    .titleContainer{
        display: block !important;
    }
    .input{
        width: 88%;
    }
}

@media (min-width:601px) and (max-width:768px) {
    .btnContainer{
        width: 100% !important;
        justify-content: space-evenly !important;
    }
    .searchContainer{
        width: 64% !important;
    }
    .addText{
        font-size: 12px !important;
    }
    .addMemberContainer{
        padding: 13px 15px !important;
    }
    .tableChildContainer{
        width: 900px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .rowsFont{
        font-size: 12px !important;
    }
    .pageSelect{
        font-size: 12px !important;
    }
}

@media (min-width:769px) and (max-width:992px) {
    .btnContainer{
        width: 77% !important;
    }
    .tableChildContainer{
        width: 900px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .rowsFont{
        font-size: 12px !important;
    }
    .pageSelect{
        font-size: 12px !important;
    }
    .addText{
        font-size: 10px !important;
    }
    .addMemberContainer{
        padding: 13px 15px !important;
    }
}   


/* @include media-breakpoint-up(sm){
    .titleContainer{
        display: block;
    }

} */