.overallContainer {
  width: 100%;
  float: left;
}
.leftContainer {
  width: 23%;
  float: left;
}
.wallet {
  width: 100%;
  height: 170px;
  background: linear-gradient(104.04deg, #ff7b26 6.29%, #ff8832 98.48%);
  border-radius: 7px;
  position: relative;
}
.walletInnerImg {
  width: 152px;
  position: absolute;
  right: 0;
}
.walletContent {
  padding-top: 20px;
  padding-left: 30px;
}
.balHistory {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 41px;
  bottom: 31px;
  text-decoration: underline;
}
.balHistoryImg {
  width: 15px;
  height: 15px;
  margin-left: 7px;
}
.trackContainer {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  height: 150px;
  margin-top: 10px;
}
.trackInput {
  border: none;
  outline: unset;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 10px 6px;
  font-size: 13px;
}
.trackInputContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.trackBtn {
  background-color: #ffb230;
  border: none;
  font-size: 13px;
  padding: 7px 16px;
  border-radius: 6px;
}
.trackOrderId {
  padding-left: 15px;
  padding-top: 15px;
}
.trackBtnContainer {
  padding-left: 11px;
  margin-top: 13px;
}
.servicesContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.leftChildContainer {
  padding: 10px 15px; background: #fff;
}
.rightContainer {
  width: 48%;
  float: left;
  padding: 20px;
  background: #f8f3f0;
  /* min-height: calc(100vh - 68.5px); */
  min-height: 909px;
}
.rightChartContainer {
  width: 100%;
  float: left;
}
.leftSideChart {
  width: 50%;
  float: left;
}
.topupText {
  position: absolute;
  top: 3px;
  right: 13px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}
.rightSideChart {
  width: 45%;
  float: left;
  display: flex;
  /* justify-content: space-evenly; */
}
.rightSideChild {
  width: 150px;
  height: 170px;
  background-color: #ffb230;
  border-radius: 10px;
  margin: 0 2.5%;
}
.rightSideChild2 {
  width: 150px;
  height: 170px;
  background-color: #ed6c44;
  border-radius: 10px;
  margin: 0 2.5%;
}
.rightSideTitle {
  color: white;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0;
}
.rightSideOrder {
  margin-bottom: 0;
  font-size: 10px;
  color: white;
  font-weight: bold;
}
.rightContentContainer {
  padding: 30px 17px;
}
.drawImg {
  width: 111px;
  margin-left: 20px;
}
.moneyChildContainer {
  max-width: 95%;
  width: 280px;
  height: 125px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: white;
}
.moneyContainer {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.transferBtn {
  background-color: #4c4743;
}
.requestBtn {
  background-color: #ed6c44;
}
.money {
  color: #ffb230;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  margin: 5px 0;
  word-break: break-word;
}
.moneyTitle {
  font-size: 15px;  
  text-align: center;
}
.moneyDetails {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}
.earningContainer {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 2px solid #ffb230;
  display: flex;
  justify-content: center;
}
.earnImg {
  width: 20px;
  height: 31px;
  align-self: center;
}
.recentActivityContainer {
  width: 100%;
  float: left;
  background: white;
  padding: 22px 30px;
  border-radius: 10px;
  position: relative;
}
.lastContainer {
  width: 29%;
  float: left;
  padding: 10px 15px;
  background: #fff;
}
.searchInput {
  border: none;
  outline: unset;
  padding: 5px 15px;
  background-color: #f5f5f5;
  width: 91%;
}
.searchChildContainer {
  display: none;
  background: #f5f5f5;
  width: 100%;
  padding: 7px 11px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: space-between;
}
.searchImg {
  width: 25px;
  cursor: pointer;
}
.paymentContainer {
  background-color: #f5f5f5;
  width: 100%;
  float: left;
  padding: 12px 20px;
}
.paymentDetailsContainer {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
.paymentDetailsParent {
  max-height: 365px;
  overflow: auto;
  margin-top: 20px;
}
.clearall {
  font-size: 12px;
  font-weight: bold;
}
.walletContainer {
  width: 100%;
  float: left;
  background-color: #f5f5f5;
  width: 100%;
  float: left;
  padding: 12px 20px;
}
.walletFilter {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.walletFilter:hover {
  color: red;
}
.walletFilterContainer {
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-self: center;
}
.WalletParent {
  max-height: 365px;
  overflow: auto;
  margin-top: 20px;
}
.cancelImg {
  width: 20px;
  height: 20px;
  align-self: center;
}
.cancelImgContainer {
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 6px;
}
.paymentMsg{
    font-size: 20px;
font-weight: bold;
text-align: center;
padding: 8px 0;
}
.modalH {
  height: 190px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.errorImg {
  width: 30px;
  height: 30px;
}
.errorImgContainer {
  text-align: center;
}
.ourService {
  margin-top: 25px;
}
.noDataFoundMsg {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
/* .noDataFoundMsgContainer{
    height: 60px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
padding: 8px 15px;
margin-bottom: 15px;
border-radius: 5px;
} */
.trackOrder {
  margin-top: 15px;
}
@media (max-width: 768px) {
  .lastContainer,
  .rightContainer,
  .leftContainer,
  .leftChildContainer .trackOrder,
  .recentActivityContainer,
  .chartSec,
  .recentActivityContainer,
  .leftSideChart,
  .rightSideChart,
  .moneyChildContainer {
    width: 100% !important;
  }
  .rightSideChart {
    margin-top: 20px;
  }
  .moneyContainer {
    display: block;
  }
  .lastContainer {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .leftContainer {
    padding: 15px 0 20px 0;
  }
  .rightContainer {
    min-height: auto !important;
  }
  .leftContainer,
  .rightContainer,
  .lastContainer {
    width: 100% !important;
  }
  .walletDetails,
  .ourService {
    width: 37.5%;
    float: left;
    margin-top: 0 !important;
  }
  .trackOrder {
    width: 25%;
    float: left;
    margin-top: 0 !important;
  }
  .chartSec {
    width: 58%;
    float: left;
  }
  .recentActivityContainer {
    margin-left: 15px;
    width: calc(42% - 15px);
    float: left;
    margin-top: 0 !important;
  }
}
