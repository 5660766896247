.parentContainer{
    background-color: #F8F3F0;
    padding: 0 !important;
}
.childContainerParent{
    padding: 18px;
    min-height: calc(100vh - 68.5px);
}
.childContainer{
    background-color: white;
}
.imgContainer{
    width: 40%;
height: 37px;
background-color: #ff7b26;
display: flex;
justify-content: center;
border-radius: 5px;
align-self: center;
}
.csvParentContainer{
    width: 340px;
    display: flex;
    justify-content: space-between;
}
.addText{
    color: white;
    align-self: center;
    font-size: 15px !important;
}
.imgParentContainer{
    width: 100%;
padding: 15px;
cursor: pointer;
display: flex;
justify-content: space-between;
/* padding-bottom: 0; */
}
thead{
    background: #f5f5f5;
}
.actionsIcon{
    width: 14px;
height: 14px;
align-self: center;
cursor: pointer;
}
.actionsIconContainer{
    display: flex;
/* justify-content: space-around; */
padding: 11px 0;
}
.IconChildContainer{
    width: 25px;
height: 25px;
background-color: #f5f5f5;
border-radius: 50%;
display: flex;
justify-content: center;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.paginationContainer{
    width: 100%;
display: flex;
justify-content: end;
}
.actionsHead{
    width: 8%;
}
.deleteConfirmationBtnContainer{
    width: 100%;
    float: left;
    margin-top: 15px;
    display: flex;
justify-content: end;
}
.deleteConfirmationOkBtn{
    width: 50%;
    float: left;
    background-color: #ff7b26;
    border: none;
    padding: 10px 0;
color: white;
font-weight: bold !important;
}
.deleteConfirmationCancelBtn{
    width: 50%;
    float: left;
    background-color: white;
    border: none;
    padding: 10px 0;
color: black;
font-weight: bold !important;
}
.CsvBtn{
    width: 40%;
height: 37px;
border: 1px solid #ff7b26;
background: white;
border-radius: 5px;
color: #ff7b26;
font-size: 13px;
font-weight: bold;
text-align: center;
text-transform: uppercase;
}
.approveDate{
    width: 15%;
    font-size: 15px !important;
    font-weight: 600 !important;
}
.download{
    width: 20px;
height: 20px;
margin-left: 6px;
}
.btnParent{
    width: 60%;
}
.filterParentContainer{
    /* display: flex;
    justify-content: space-between; */
    padding: 17px 38px;
}
.categories{
    width: 20%;
    position: relative;
}
.filterInput{
    height: 37px;
border: 1px solid rgba(0,0,0,0.1);
border-radius: 5px;
padding: 0 15px;
outline: unset;
width: 100%;
margin: 5px 0;
}
.searchBtn{
    width: 150px;
    height: 37px;
    border: none;
    background: #ff7b26;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    text-transform: uppercase;
    
}
.resetBtn{
    width: 150px;
    height: 37px;
    border: 1px solid #ff7b26;
    background: white;
    border-radius: 5px;
    color: #ff7b26;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    text-transform: uppercase;
    
}
.select{
    font-size: 15px;
    letter-spacing: 1px;
    outline: unset;
    height: 37px;
    width: 100%;
    padding: 0 10px;
    background: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    margin: 5px 0;
  }
  select{
      padding-right: 2rem !important;
  }
  tr:nth-child(even) {background-color: #f2f2f2;}

  .price{
    display: flex;
justify-content: center;
height: 47px;

}
.priceAmt{
    width: 75px;
text-align: start;
align-self: center;
}
.priceSymbol{
    text-align: right;
padding-right: 5px;
width: 25%;
align-self: center;
}
.searchIcon{
    width: 20px;
    height: 20px;
    align-self: center;
    cursor: pointer;
}
.addIcon{
    width: 12px;
height: 12px;
}
.tableHead{
    padding: 15px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}
.actionsHead{
    padding: 15px 0;
    font-size: 15px !important;
    font-weight: 600 !important;
}
@media only screen and (max-width:450px) {
   .TableChildContainer{
       width: 1100px !important;
   }
   .tableParentContainer{
       overflow-x: auto !important;
   }
   .actionsHead{
       width: 11%;
       font-size: 12px;
   }
   .tableHead{
       font-size: 12px;
   }
   .td{
    font-size: 11px;
   }
   .IconChildContainer{
       width: 20px;
       height: 20px;
   }
   .actionsIcon{
       width: 12px;
       height: 12px;
   }
   .filterParentContainer{
       display: block;
   }
   .categories{
       width: 100%;
       padding: 8px 0;
   }
   .filterInput{
       width: 100%;
   }
   .select{
       width: 100%;
   }
   /* .searchBtn{
width: 100%;
   } */
   .childContainerParent{
       padding: 30px 20px;
   }
   .imgParentContainer{
       flex-direction: column;
       padding: 10px 15px;
   }
   .csvParentContainer{
       width: 100%;
       padding: 10px 0;
   }
   .filterParentContainer{
       padding: 10px 15px;
       padding-top: 0;
   }
   .filterInput{
       background-color: white;
   }
   .CsvBtn{
       font-size: 11px;
   }
   .addText{
       font-size: 11px;
   }
   .approveDate{
       font-size: 12px;
   }
   .searchIcon{
       width: 20px;
       height: 20px;
   }
  }

  @media (min-width:451px) and (max-width:768px) {
    .TableChildContainer{
        width: 1150px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .approveDate{
        font-size: 12px;
    }
    .actionsHead{
        width: 11%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        flex-wrap: wrap;
    }
    .categories{
        width: 48%;
        padding: 8px 0;
    }
    .select{
        width: 100%;
    }
    .filterInput{
        width: 100%;
    }
    .imgParentContainer{
        flex-direction: row;
        padding: 10px 15px;
    }
    .csvParentContainer{
        width: 225px;
        padding: 10px 0;
    }
    .filterParentContainer{
        padding: 10px 15px;
        padding-top: 0;
    }
    .filterInput{
        background-color: white;
    }
    .CsvBtn{
        font-size: 11px;
        width: 50%;
    }
    .addText{
        font-size: 11px;
    }
    .csvContainer{
        display: flex;
        justify-content: end;
    }
    .Title{
        align-self: center;
        font-size: 15px !important;
    }
    .imgContainer{
        width: 25%;
    }
  }

  @media (min-width:769px) and (max-width:992px) {
   
    .TableChildContainer{
        width: 1150px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .approveDate{
        font-size: 12px;
    }
    .actionsHead{
        width: 11%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        flex-wrap: wrap;
    }
    .categories{
        width: 48%;
        padding: 8px 0;
    }
    .select{
        width: 100%;
    }
    .filterInput{
        width: 100%;
    }
    .csvParentContainer{
        width: 47%;
        padding: 10px 0;
    }
    
  }

  @media (min-width:993px) and (max-width:1140px) {
    .TableChildContainer{
        width: 1150px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .approveDate{
        font-size: 12px;
    }
    .actionsHead{
        width: 11%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        flex-wrap: wrap;
    }
    .categories{
        width: 48%;
        padding: 8px 0;
    }
    .select{
        width: 100%;
    }
    .filterInput{
        width: 100%;
    }
    .csvParentContainer{
        width: 34%;
    }
  }

  @media (min-width:1141px) and (max-width:1200px) {
    .TableChildContainer{
        width: 1150px !important;
    }
    .tableParentContainer{
        overflow-x: auto !important;
    }
    .approveDate{
        font-size: 12px;
    }
    .actionsHead{
        width: 11%;
        font-size: 15px;
    }
    .tableHead{
        font-size: 15px;
    }
    .td{
     font-size: 13px;
    }
    .IconChildContainer{
        width: 25px;
        height: 25px;
    }
    .actionsIcon{
        width: 14px;
        height: 14px;
    }
    .filterParentContainer{
        flex-wrap: wrap;
    }
    .categories{
        width: 48%;
        padding: 8px 0;
    }
    .select{
        width: 100%;
    }
    .filterInput{
        width: 100%;
    }
    .csvParentContainer{
        width: 32%;
    }
  }