.parentDetails {
  width: 100%;
  float: left;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}

.leftDetails {
  width: 50%;
  float: left;
  padding: 0px 10px;
}

.rightDetails {
  width: 50%;
  float: left;
  padding: 0px 10px;
}

.unitColor {
  display: flex;
  justify-content: space-between;
}

.details {
  font-size: 15px;
  padding: 0;
  overflow-wrap: break-word;
}

.productName {
  cursor: pointer;
}

.cartProductName {
  width: 40%;
}

.readmore {
  font-size: 13px;
  font-weight: bold;
}

.shippingDetails {
  font-size: 15px;
  padding: 0;
  overflow-wrap: break-word;
}

.tableParent {
  width: 100%;
  float: left;
  margin-top: 35px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 30px;
  background: #fff;
  border-radius: 30px;
}

.th {
  padding: 10px 0;
}

.btn {
  background-color: #ff7b26;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  text-transform: uppercase;
}

.PreviousBtn {
  background-color: white;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid #ff7b26;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 15px;
  text-transform: uppercase;
}

.btnParent {
  width: 100%;
  float: left;
  display: flex;
  justify-content: end;
  margin: 25px 0;
}

.nextIcon {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  margin-right: 10px;
}

.nextWhite {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.price {
  display: flex;
  justify-content: center;
  height: 44px;
}

.priceAmt {
  width: 75px;
  text-align: start;
  align-self: center;
}

.priceSymbol {
  text-align: right;
  padding-right: 5px;
  width: 25%;
  align-self: center;
}

.priceSymbol1 {
  text-align: right;
  padding-right: 5px;
  width: 8%;
  align-self: center;
}

.swichContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.switchChildContainer {
  display: flex;
  width: 15%;
  justify-content: space-between;
}

.switchTitle {
  font-size: 15px;
  align-self: center;
  font-weight: 500;
}

.paymentMode {
  width: 100%;
  float: left;
  text-align: end;
  font-size: 14px;
  font-weight: bold;
}

.mobileCart .item {
  border: 1px solid #ededed;
  padding: 10px;
  border-radius: 5px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.mobileCart .item img {
  float: left;
  margin-right: 5px;
}

.mobileCart .item .clsicon {
  float: right;
  margin-right: 0;
  background: #fff;
  border-radius: 10px;
}

.mobileCart .item p.title {
  margin-bottom: 5px;
  font-size: 13px;
  max-height: 35px;
  overflow: hidden;
}

.mobileCart .item p.title a {
  text-decoration: none;
  color: #000;
}

.mobileCart .item p.price {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 14px;
  overflow: hidden;
  float: right;
  font-weight: 700;
}

.mobileCart .item .quantity {
  margin-right: 10px;
  font-size: 13px;
  float: left;
}

.toatalPrice {
  font-size: 18px;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}

.toatalPrice span {
  color: #ff7b26;
  font-weight: 600;
}

.paymentMsg {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 8px 0;
}

@media only screen and (max-width: 450px) {
  .parentDetails {
    padding: 15px;
  }

  .leftDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .rightDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .details {
    padding: 0;
  }

  .shippingDetails {
    padding: 0;
  }

  .childTable {
    width: 1000px;
  }

  .ParentTable {
    overflow-x: auto;
  }

  .tableParent {
    padding: 10px;
  }

  .swichContainer {
    display: block;
  }

  .switchChildContainer {
    width: 100%;
  }

  .switchTitle {
    width: 82%;
    text-align: end;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .childTable {
    width: 1000px;
  }

  .ParentTable {
    overflow-x: auto;
  }

  .tableParent {
    padding: 10px;
  }

  .cartProductName {
    width: 25%;
  }

  .switchChildContainer {
    width: 25%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .childTable {
    width: 900px;
  }

  .ParentTable {
    overflow-x: auto;
  }

  .tableParent {
    padding: 10px;
  }

  .cartProductName {
    width: 25%;
  }

  .switchChildContainer {
    width: 25%;
  }
}

@media (min-width: 993px) and (max-width: 1140px) {
  .childTable {
    width: 900px;
  }

  .ParentTable {
    overflow-x: auto;
  }

  .tableParent {
    padding: 10px;
  }

  .cartProductName {
    width: 25%;
  }

  .switchChildContainer {
    width: 20%;
  }
}