.attributesContainer{
    width: 100%;
    float: left;
  }
  .attributeName{
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
  .headingTextContainer{
    margin-top: 10px;
  }

 
  .hr1 {
    height: 3px !important;
    width: 25%;
    color: #ff7c25;
  }
  .hr2 {
    height: 3px !important;
    width: 75%;
  }
  .checkbox {
    overflow: auto;
    cursor: pointer;
  }
  .checkboxContainer {
    scrollbar-color: #FF7C25 #fff;
    max-height: 250px;
    overflow: hidden;
  }
  .input {
    box-shadow: unset !important;
    cursor: pointer;
  }
 
  .searchBtn{
    background: #FF7C25;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 45px;
    border-radius: 5px;
    height: 45px;
    align-self: center;
    /* width: 100% !important; */
    margin: 15px 0;;
    text-transform: uppercase;
    }