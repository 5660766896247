.img{
    max-width: 500px;
}
.test {
    margin-top: 25px;
    font-size: 21px;
    text-align: center;
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
/* @-webkit-keyframes fadein {  Safari and Chrome 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
} */
/* @-o-keyframes fadein {  Opera 
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
} */

.ParentContainer{
    display: flex !important;
}
.content{
    width: 50% !important;
}
.flowImg{
    height: 300px;
    width: 1000px;
    margin: 10% 0;

}
.para{
    text-align: justify !important;
    font-size: 14px !important;
    line-height: 30px;
}

@media only screen and (max-width:600px) {
    .ParentContainer{
        display: block !important;
    }
    .img{
        max-width: 292px !important;
    }
    .content{
        width: 100% !important;
    }
    .para{
        text-align: justify !important;
        font-size: 14px !important;
        line-height: 30px;
    }
    .flowImg{
        height: 100px;
width: 300px;
    }
    
}

@media (min-width:601px) and (max-width:768px) {
    .ParentContainer{
        display: block !important;
    }
    .img{
        max-width: 500px !important;
    }
    .content{
        width: 100% !important;
    }
    .para{
        text-align: justify !important;
        font-size: 14px !important;
        line-height: 30px;
    }
    .flowImg{
        height: 200px;
width: 650px;
    }
    
}

