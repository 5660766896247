.imgContainer {
  width: 230px;
  margin-right: 25px;
  filter: grayscale(1);
}
.lgImg {
  width: 230px;
  margin-right: 25px;
  filter: grayscale(0);
}
.imgContainer:hover {
  filter: grayscale(0);
}
.lgImg:hover {
  filter: grayscale(0);
}
.brandContainer {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .lgImg {
    width: 312px !important;
  }
  .imgContainer {
    width: 312px !important;
  }
}
