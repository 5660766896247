.cancelImg {
  width: 20px;
  height: 20px;
  align-self: center;
}
.cancelImgContainer {
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.cancelImgParentContainer {
  display: flex;
  justify-content: space-between;
}
.productname {
  cursor: pointer;
}
.img {
  width: 15px;
  height: 15px;
  align-self: center;
}
.location {
  width: 15px;
  height: 15px;
}
.username {
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  align-self: center;
}
.detailContainer {
  display: flex;
  padding: 4px 0;
  align-self: center;
  width: 100%;
}
.detail {
  font-weight: normal;
  padding-left: 15px;
  font-size: 15px;
}
.data {
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  min-height: 40px;
  overflow: auto;
}
.td {
  padding: 5px 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.th {
  padding: 6px 0;
}
.productname {
  width: 50%;
}
.usernameContainer {
  width: 50%;
  float: left;
  display: flex;
}
.usernameParentContainer {
  display: flex;
  padding: 4px 0;
  width: 100%;
  float: left;
}
.btn {
  background-color: #ff7b26;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 6px 10px;
  border-radius: 5px;
}
.btnContainer {
  text-align: end;
  padding: 10px 10px 0 10px;
}
@media only screen and (max-width: 450px) {
  .childContainer {
    width: 400px;
  }
  .parentContainer {
    overflow-x: auto;
  }
  .cancelImg {
    width: 15px;
    height: 15px;
  }
  .cancelImgContainer {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 993px) and (max-width: 1140px) {
}

@media (min-width: 1141px) and (max-width: 1200px) {
}
