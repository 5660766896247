.input {
  background: #222;
  color: #fff;
  border: none;
  height: 38px;
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  outline: unset;
}
.inputContainer {
  margin: auto;
  width: 250px;
  color: #fff;
  margin-top: 40px;
  display: flex;
}

.shopList {
  display: flex;
  flex-direction: column;
  background: #111;
  border-radius: 10px;
  padding: 15px;
  color: #bbb;
  min-height: 158px;
}
.shopListContainer {
  width: 300px;
  margin-right: 30px;
}

.parentContainer {
  padding: 100px 0;
}
.searchIcon {
  width: 20px;
  height: 20px;
  align-self: center;
  margin-left: 15px;
  cursor: pointer;
}
.errMsg {
  width: 250px;
  margin: auto;
  color: red;
  font-size: 13px;
  font-weight: bold;
}
.img{
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
@media only screen and (max-width: 600px) {
  .shopListContainer {
    width: 330px !important;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .shopListContainer {
    width: 280px !important;
  }
}
