.overallContainer {
  width: 100%;
  float: left;
}
.LeftContainer {
  width: 50%;
  float: left;
  background: #fff;
  border-radius: 40px;
  min-height: 400px;
}
.RightContainer {
  width: 50%;
  float: left;
  padding-top: 14px;
  padding: 15px;
  max-height: 400px;
  overflow: auto;
}
.imgContainer {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;     align-items: center;
}
.imgContainer .largeimg {
  max-width: 100%;
  max-height: 400px;
}
.img {
  width: 110px;
  height: 110px;
  align-self: center;
}
.readmore {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}
.details {
  font-size: 13px;
  clear: both;
}
.productname {
  font-weight: 600;
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
}
.details1 {
  font-weight: normal;
}
.productcode {
  background: #fff;
  display: inline;
  color: #000;
  margin: 5px 0;
  /* float: left; */
  clear: both;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 5px;
}
.productCount{
  width: 85px;
background: #f5f5f5;
border: none;
height: 33px;
padding: 0px 8px;
margin-left: 3%;
outline: unset;
}
.color{
  height: 25px;
width: 25px;
border-radius: 50%;
/* border: 1px solid white; */
margin: 0 0 10px 0px;
cursor: pointer;
}
.unitButtonContainer{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.customerAddBtn{
  background-color: #ff8a3e;
  font-size: 12px;
  height: 30px;
  color: white;
  font-weight: bold;
  border: none; float: right;
  padding: 6px 5px;
  text-transform: uppercase;
  width: 70px;
  border-radius: 30px;
  float: right;
}
.cancelImgContainer {
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: -8px;
  z-index: 1;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 1px;
}
.cancelImgParentContainer {
  display: flex;
  justify-content: space-between;
}
.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@media (max-width: 768px) {
  .LeftContainer,
  .RightContainer {
    width: 100%;
    height: auto;
    min-height: auto !important;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.oldPrice {
  text-decoration: line-through; font-size: 15px; color: #222; font-weight: normal;
}
.price {
  font-size: 20px; margin: 10px 0 5px 0;
  font-weight: 600; color: #ff7b26;
}
