.OverallFilterContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
    padding: 15px 10px;

}
.searchInputContainer{
    border: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    background-color: #fff1e7;
    outline: unset;
    height: 47px;
    border-radius: 5px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchInput{
    border: 0px ;
    /* padding: 10px 25px;
    margin: 10px 0; */
    width: 85%;
    background-color: #fff1e7;
    outline: unset;
    font-size: 16px;
    /* height: 47px; */
    /* border-radius: 5px; */
    /* align-self: center; */
}
.searchBtn{
background: #FF7C25;
border: none;
color: white;
font-size: 13px;
font-weight: bold;
padding: 10px 45px;
border-radius: 5px;
height: 47px;
align-self: center;
width: 85% !important;
margin: 10px 0;;
text-transform: uppercase;
}
.SwitchParent{
    display: flex;
    justify-content: end;
    margin: 10px 0;
   
}
.filterTitle{
  font-size: 17px;
  font-weight: 400;
}
.switchTitle{
    align-self: center;
    font-size: 13px;
    font-weight: bold;
    margin-right: 15px;
}
.AttributesContainer{
    width: 100%;
    float: left;
}
.attributeName{
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}
.attributeValuesContainer{
    padding-left: 15px;
}
.checkBoxLabel{
    padding-left: 5px;
}


.attributesContainer{
    width: 100%;
    float: left;
  }
  .attributeName{
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
  .headingTextContainer{
    margin-top: 10px;
  }

 
  .hr1 {
    height: 3px !important;
    width: 25%;
    color: #ff7c25;
  }
  .hr2 {
    height: 3px !important;
    width: 75%;
  }
  .checkbox {
    overflow: auto;
    cursor: pointer;
  }
  .checkboxContainer {
    scrollbar-color: #FF7C25 #fff;
    max-height: 250px;
    overflow: hidden;
  }
  .input {
    box-shadow: unset !important;
    cursor: pointer;
  }
  .MuiAccordionSummaryContent{
    margin: 5px 0px !important;
  }
  .MuiAccordionDetailsRoot{
    padding: 0px 16px !important;
  }