.bg {
  width: 100%;
  height: 55px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: center;
}
.img {
  /* width: 30px; */
  height: 30px;
  align-self: center;
}
.parentContainer {
  width: 23%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 0;
  cursor: pointer;
  margin: 0 14px;
}
.serviceDescription {
  padding-top: 7px;
  /* text-transform: capitalize; */
  text-align: center;
  letter-spacing: 1px;
}

@media (min-width: 451px) and (max-width: 768px) {
  .parentContainer {
    width: 10%;
  }
}
@media (min-width: 650px) and (max-width: 1100px) {
  .parentContainer {
    padding: 5px 0;
  }
  .bg {
    height: 50px;
  }
}
