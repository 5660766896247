.overallParent {
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  float: left;
  width: 100%;
}
.loginRightContainer {
  width: 100%;
  float: left;
  padding: 30px;
  text-align: left;
}
.visibleImg {
  width: 20px;
  height: 20px;
}
.inputContainer {
  width: 100%;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 9px;
  margin-top: 5px;
}
.emailInput {
  border: none;
  height: 50px;
  outline: unset;
  padding: 0 15px;
  width: 90%;
}
.passwordInput {
  border: none;
  height: 50px;
  outline: unset;
  padding: 0 15px;
  width: 93%;
}
.loginBtn {
  width: 100%;
  height: 50px;
  background-color: #ff7c25;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 9px;
}
.forgotPassword {
  text-decoration: none;
  color: black;
}
.forgotpasswordContainer {
  text-align: center;
  margin-top: 15px;
}
.errMsg {
  font-size: 12px;
  font-weight: bold;
}
.error {
  font-size: 12px;
  font-weight: bold;
  color: #f74a4a;
  text-align: end;
  padding-right: 7px;
}
.count {
  display: flex;
  justify-content: end;
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
}
.resendOtpText {
  font-size: 13px;
  float: right;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 4px;
  color: #ff7c25;
  cursor: pointer;
}
.eyeIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 450px) {
  .loginRightContainer {
    padding: 20px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
  .loginRightPara {
    width: 100%;
    font-size: 12px;
  }
  .overallParent {
    width: 100%;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .loginRightPara {
    width: 100%;
    font-size: 13px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .loginRightContainer {
    margin: 0;
    width: 55%;
    padding: 15px 25px;
  }
  .loginRightPara {
    width: 100%;
    font-size: 13px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
}
