.input {
    background-color: #000;
    border: none;
    outline: unset;
    color: white;
    width: 100%;
  }
  .inputContainer {
    border-bottom: 1px solid white;
    width: 95%;
    padding: 10px 0;
  }
  .timeContainer{
    padding: 13px 0;
  }
  .AddressinputContainer {
    border-bottom: 1px solid white;
    width: 100%;
    padding: 10px 0;
  }
  .select {
    background-color: #000 !important;
    border: none !important;
    color: white !important;
    box-shadow: unset !important;
    background-image: unset !important;
  }
  
  .thirdChildContainer {
    width: 50%;
    margin-right: 15px;

  }
  .deleteIcon{
    width: 20px;
height: 20px;
align-self: center;
  }
  
  .radioLabel {
    margin-left: 10px;
  }
  
  .radioContainer {
    margin-right: 10px;
    display: flex;
  }
  .radioInput{
      padding: 25px 0;
  }
  .progressbar{ 
    max-width: 650px;
    margin: 50px auto;
    overflow: hidden;
    color: lightgrey;
    display: flex;
    justify-content: center;
    padding: 0;
    height: 80px;
  }
  .progressbar li{
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
  }

  .nextBtn{
    width: 120px;
    background: #ff7b26;
      background-color: #ff7b26;
    color: white;
    font-size: 16px;
    border: 0 none;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px 10px 5px;
  }
  .previousBtn{
    width: 120px;
    background: ghostwhite;
      background-color: ghostwhite;
    color: black;
    font-size: 16px;
    border: 0 none;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px 10px 5px;
  }
  .liContainer{
    width: 50px;
    background-color: #ff7b26;
    height: 50px;
    border-radius: 50%;
    position: relative;
  }
  .li_img{
    width: 25px;
    position: absolute;
    right: -24px;
    bottom: -15px;
  }
.li_shopping{
  width: 25px;
  position: absolute;
  right: -25px;
  bottom: -17px;
}
.hr{
    width: 80px;
margin-top: 25px;
height: 3px !important;
background-color: #ff7b26;
}
.secondInputContainer{
    display: flex;
width: 100%;
justify-content: space-evenly;
}
.label{
    width: 33% !important;
}
.businessLabel{
    margin-right: 25px;
    width: 100%;
    float: left;
}
.businessCheckbox{
    width: 39%;
}
.othersContainer{
    margin-left: 25px;
}
/* .checkbox{
    width: 25px;
height: 25px;
border: 1px solid white;
} */
.storeCheckbox{
    display: flex;
width: 23%;
justify-content: space-between;
}
.storeFloorCheckbox{
    display: flex;
width: 26%;
justify-content: space-between;
}
.success{
  font-size: 25px;
  color: #ff7b26;
  text-transform: uppercase;
font-weight: bold;
text-align: center;
}
.formContainer{
  margin-left: 28%;
}

.positonName{
  position: absolute;
  bottom: -28px;
  left: 0;
  font-size: 13px;
}
.positionStore{
  position: absolute;
  bottom: -28px;
  left: 12;
  font-size: 13px;
}
.nextBtn:hover{
  background-color: white;
  color: black;
}
.previousBtn:hover{
  background-color: #ff7b26;
  color: white;
}
.radioBtn{
  width: 20px;
height: 20px;
/* border-radius: 10px;
background: none;
border: 2px solid #9f9f9f;
box-shadow: inset 0 0 0 1px #9f9f9f;
box-shadow: inset 0 0 0 1.5px #9f9f9f;
appearance: none;
padding: 0;
margin: 0;
transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
pointer-events: none; */
}
/* Loader */

.loaderParent{
  width: 99%;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
background-color: rgba(0,0,0,0.5);
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.state{
  width: 95%;
border-bottom: 1px solid white;
display: flex;
}
.city{
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
}

.formFirst{
  display: flex !important;
}
.firstInputContainer{
  width: 50% !important;
}
.secondInputContainer{
  display: flex;
}
.fourthInputContainer{
  display: flex;
}
.businessContainer{
  display: flex !important;
}
.businessFirstContainer{
  display: flex !important;
}
.businessFirst{
  width: 50% !important;
}
.businessSecondContainer{
  display: flex !important;
}
.parentStateContainer{
  width: 48%;
}
@media only screen and (max-width:600px) {
  .formFirst{
    display: unset !important;
  }
  .inputContainer{
    width: 100% !important;
  }
  .firstInputContainer{
    width: 100% !important;
    padding: 15px 0;
  }
  .secondInputContainer{
    display: unset !important;
  }
  .AddressinputContainer{
    width: 100% !important;
  }
  .thirdChildContainer{
    width: 100% !important;
    padding: 15px 0;
  }
  .fourthInputContainer{
    display: unset !important;
  }
  .businessContainer{
    display: unset !important;
  }
  .businessFirstContainer{
    display: unset !important;
  }
  .label{
    width: 100% !important;
  }
  .businessFirst{
    width: 100% !important;
    padding: 15px 0;
  }
  .businessSecondContainer{
    display: unset !important;
  }
  .businessCheckbox{
    width: 100% !important;
    padding: 15px 0;
    display: unset !important;
  }
  .othersContainer{
    margin-left: unset !important;
  }
  .storeCheckbox{
    width: 100% !important;
    justify-content: space-evenly !important;
  }
  .storeFloorCheckbox{
    width: 100% !important;
    justify-content: space-evenly !important;
  }
  .liContainer{
    width: 45px;
    height: 38px;
  }
  .li_img{
    right: -22px;
bottom: -7px;
  }
  .li_shopping{
    right: -22px;
bottom: -12px;
  }
  .hr{
    margin-top: 19px;
  }
  .parentStateContainer{
    width: 100%;
    padding: 15px 0;
  }
  .state{
    width: 100%;
  }
}

@media (min-width:601px) and (max-width:768px) {
  .businessSecondContainer{
    display: flex !important;
  }
  .thirdChildContainer{
    width: 60% !important;
  }
  .storeCheckbox{
    width: 35% !important;

  }
  .storeFloorCheckbox{
    width: 40% !important;

  }
  
}

@media (min-width:769px) and (max-width:1300px) {
  .businessSecondContainer{
    display: flex !important;
  }
  .thirdChildContainer{
    width: 70% !important;
  }
}