.cancelImg{
    width: 20px;
    height: 20px;
    align-self: center;
  }
  .cancelImgContainer{
    width: 30px;
height: 30px;
background-color: #f5f5f5;
border-radius: 50%;
display: flex;
justify-content: center;
cursor: pointer;
  }
  .cancelImgParentContainer{
display: flex;
justify-content: space-between;
  }
  .img{
    width: 15px;
    height: 15px;
    align-self: center;
  }
  .location{
    width: 15px;
    height: 15px;
  }
  .username{
    font-size: 15px;
    font-weight: bold;
    padding-left: 15px;
    align-self: center;
  }
.detailContainer{
  display: flex;
  padding: 4px 0;
  align-self: center;
  width: 100%;
  float: left;
}
.detailUserContainer{
  display: flex;
  padding: 4px 0;
  align-self: center;
  width: 100%;
  float: left;
}
.userLeftContainer{
  width: 50%;
  float: left;
  display: flex;
}
.userRightContainer{
  width: 50%;
  float: left;
}
.detail{
  font-weight: normal;
  padding-left: 15px;
  font-size: 15px;
  overflow: auto;
  overflow-wrap: break-word;
}

.data{
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  min-height: 40px;
  overflow: auto;
}
.td{
  padding: 5px 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.productname{
  cursor: pointer;
}
.th{
  padding: 6px 0;
}
.productname{
  width: 50%;
}
/* .tableParentContainer{
  overflow-x: auto;
}
.tableChildContainer{
  width: 600px;
} */

@media only screen and (max-width:450px) {
  .tableParentContainer{
    overflow-x: auto;
  }
  .tableChildContainer{
    width: 600px;
  }
}

@media (min-width:451px) and (max-width:768px) {
  .tableParentContainer{
    overflow-x: auto;
  }
  .tableChildContainer{
    width: 600px;
  }
}

@media (min-width:769px) and (max-width:992px) {
  
}

@media (min-width:993px) and (max-width:1140px) {
  
}

@media (min-width:1141px) and (max-width:1200px) {
  
}
