.loginRightContainer{
    width: 50%;
    float: left;
    height: 600px;
    padding: 10px 25px;
}

.loginRightPara{
    color: rgba(0,0,0,0.7);
    width: 75%;
}
.inputContainer{
    width: 90%;
height: 50px;
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
border-radius: 6px;
float: left;
}
.emailInput{
    border: none;
height: 50px;
outline: unset;
padding: 0 15px;
width: 100%;
}
.loginBtn{
    width: 45%;
height: 50px;
background-color: #FF7C25;
border: none;
color: white;
font-weight: bold;
border-radius: 9px;
padding: 13px 15px;
text-align: center;
text-decoration: none;
}
.backtoLoginBtn{
    border: 1px solid #FF7C25;
    background-color: white;
    color: rgba(0,0,0,0.5);
    padding: 13px 15px;
    border-radius: 9px;
    height: 50px;
    width: 45%;
    text-decoration: none;
    text-align: center;
}
.backtoLoginBtn:hover{
    color: rgba(0,0,0,0.5);
}
.btnContainer{
    width: 90%;
    float: left;
    display: flex;
    justify-content: space-between;
}
.errorMsg{
    font-size: 12px;
}
.loaderParent{
    width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #ff7b26;
    width: 30px;
    height: 30px;
    align-self: center;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media only screen and (max-width:450px) {
    .loginRightContainer{
        width: 100%;
        padding: 10px 20px;
        margin-left: unset
    }
    .btnContainer{
        width: 100%;
    }
    .otpInput{
        width: 100%;
    }
    .usernameContainer{
        width: 100%;
    }
    .loginRightPara{
        width: 100%;
        font-size: 13px;
    }
    .inputContainer{
        width: 100%;
    }
    .backtoLoginBtn{
        font-size: 12px;
        font-weight: bold;
    }
}

@media (min-width:451px) and (max-width:768px) {
    .loginRightContainer{
        width: 55%;
        padding: 10px 20px;
        margin-left: unset;
        margin-top: unset;
    }
    .btnContainer{
        width: 100%;
    }
    .otpInput{
        width: 100%;
    }
    .usernameContainer{
        width: 100%;
    }
    .loginRightPara{
        width: 100%;
        font-size: 13px;
    }
    .inputContainer{
        width: 100%;
    }
}

@media (min-width:769px) and (max-width:992px) {
    .loginRightContainer{
        width: 55% !important;
        padding: 10px 20px;
        margin-left: unset
    }
    .btnContainer{
        width: 100%;
    }
    .otpInput{
        width: 100%;
    }
    .usernameContainer{
        width: 100%;
    }
    .loginRightPara{
        width: 100%;
        font-size: 13px;
    }
    .inputContainer{
        width: 100%;
    }
}

@media (min-width:993px) and (max-width:1140px) {
    .loginRightContainer{
        width: 55% !important;
        padding: 10px 20px;
        margin-left: unset
    }
    .btnContainer{
        width: 100%;
    }
    .otpInput{
        width: 100%;
    }
    .usernameContainer{
        width: 100%;
    }
    .loginRightPara{
        width: 100%;
        font-size: 13px;
    }
    .inputContainer{
        width: 100%;
    }   
}

@media (min-width:1141px) and (max_width:1200px) {
    .loginRightContainer{
        width: 55% !important;
        padding: 10px 20px;
        margin-left: unset
    }
    .btnContainer{
        width: 100%;
    }
    .otpInput{
        width: 100%;
    }
    .usernameContainer{
        width: 100%;
    }
    .loginRightPara{
        width: 100%;
        font-size: 13px;
    }
    .inputContainer{
        width: 100%;
    }   
}