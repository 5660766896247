.checkbox {
    overflow: auto;
    cursor: pointer;
  }
  .checkboxContainer {
    /* height: 250px; */
    /* overflow: auto; */
    scrollbar-color: #FF7C25 #fff;
  }
  .input {
    box-shadow: unset !important;
    cursor: pointer;
  }
  .showmore{
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
  }