.overallContainer{
    width: 100%;
    float: left;
    background-color: #F8F3F0;
}
.overall{
    overflow: auto;
  height: 440px;
  }
.leftContainer{
    width: 300px;
    float: left;
    padding: 21px;
    background-color: white;
    position: sticky;
    top: 0;
}
.tabLeftContainer{
    display: none;
}
.applyBtn{
    border: none;
    color: #ff8537;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    /* padding: 0 30px; */
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    /* text-decoration: underline; */
  }
.clearall{
    font-size: 12px;
    font-weight: bold;
    align-self: center  ;
}
.hr1{
    height: 3px !important;
    width: 25%;
    color: #FF7C25;
}
.hr2{
    height: 3px !important;
    width: 75%;
}
.searchInput{
    border: none;
outline: unset;
padding: 5px 15px;
background-color: #fafafa;
width: 91%;
}
.searchChildContainer{
    display: flex;
background: #fafafa;
width: 35%;
padding: 7px 11px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 8px;
justify-content: space-between;
}
.searchImg{
    width: 25px;
    cursor: pointer;
}
.rightContainer{
    width: calc(100% - 375px);
    float: left;
    padding: 30px;
    min-height: calc(100vh - 68.5px);
    /* background-color: #F8F3F0; */
}
.selectContainer{
    width: 72%;
    align-self: center;
}
.select{
    border: none;
box-shadow: unset;
}

.mblFilter{
    display: none;
}
.select{
    border: none !important;
    box-shadow: unset !important;
    font-size: 12px !important;
    padding: 15px 8px !important;
border-radius: 9px !important;
}
.rightFilterContainer{
    width: 100% !important;
    justify-content: space-between;
    /* padding: 0 2.5% !important; */
}
.sort{
    font-size: 12px !important;
}

/* .mblProducts{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
} */
.sortText{
    /* width: 25%; */
    display: flex;
    justify-content: space-between;
}
select option[disabled]:first-child {
    display: none;
  }

 .empty{
    border: none !important;
    background-color: white;
    width: 100%;
    box-shadow: unset !important;
    font-size: 12px !important;
    padding: 15px 8px !important;
    border-radius: 9px !important;
    cursor: pointer;
  }
  /* .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */

  .notFoundImgContainer{
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .notFoundImg{
      align-self: center;
      width: 250px;
  }
  .filter{
      display: none;
  }

  .mblcart{
    width: 45px;
    position: fixed;
    height: 45px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
    z-index: 1;
  }
  .cartImg{
    width: 25px;
    height: 25px;
    align-self: center;
    
  }
  .badge{
    font-size: 12px;
    align-self: center;
    color: white;
    font-weight: bold;
}
.notification{
    background-color: #c15f25;
    min-width: 17px;
    position: absolute;
    top: 3px;
    border-radius: 50%;
    min-height: 17px;
    display: flex;
    justify-content: center;
    right: 6px;
}
.rightFilterContainer{
    display: flex;
}
.paginationContainer{
    display: flex; margin-top: 15px;
    justify-content: center;
}
@media (max-width:768px) {
.leftContainer{
    display: none;
}
.mblFilter{
    display: block;
}
.rightContainer{
    width: 100% !important;
}
.filter{
    width: 100px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}
.mblProducts{
    justify-content: space-evenly !important;
    margin-top: 25px;
}
.leftHeading{
    font-size: 12px !important;
}
.sortText{
    width: 50% !important;
}
.rightContainer{
    padding: 30px 15px !important;
    padding-top:15px !important;
}
.rightFilterContainer{
    display: block;
}
.filterText{
    align-self: center;
}
.tabLeftContainer{
    display: block;
    width: 100%;
    float: left;
    /* padding: 21px; */
    background-color: white;
}
.filterHeading{
    padding-bottom: 0 !important;
}
.searchChildContainer{
    position: sticky;
    top: 0;
    width: 100%;
}
.mblFilterContainer{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
}
@media (min-width:769px) and (max-width:992px) {
    .leftContainer{
        display: none;
    }
    .rightContainer{
        width: 100%;
    }
    .mblFilterContainer{
        justify-content: end;
    }
}   
