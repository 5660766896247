.overallParent {
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  float: left;
  width: 100%;
}
.loginRightContainer {
  width: 100%;
  float: left;
  padding: 30px;
  text-align: left;
}
.visibleImg {
  width: 20px;
  height: 20px;
}
.inputContainer {
  width: 100%;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 9px;
  margin-top: 5px;
}
.emailInput {
  border: none;
  height: 50px;
  outline: unset;
  padding: 0 15px;
  width: 90%;
}
.passwordInput {
  border: none;
  height: 50px;
  outline: unset;
  padding: 0 15px;
  width: 93%;
}
.loginBtn {
  width: 45%;
  height: 50px;
  background-color: #ff7c25;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 9px;
}
.previousBtn {
  width: 45%;
  height: 50px;
  background-color: white;
  border: 1px solid #ff7c25;
  color: #ff7c25;
  font-weight: bold;
  border-radius: 9px;
}
.forgotPassword {
  text-decoration: none;
  color: black;
}
.forgotpasswordContainer {
  text-align: center;
  margin-top: 15px;
}
.errMsg {
  font-size: 12px;
  font-weight: bold;
}
.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error {
  font-size: 12px;
  font-weight: bold;
  color: #f74a4a;
  text-align: end;
  padding-right: 7px;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 450px) {
  .loginRightContainer {
    padding: 20px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
  .loginRightPara {
    width: 100%;
    font-size: 12px;
  }
  .overallParent {
    width: 100%;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .loginRightPara {
    width: 100%;
    font-size: 13px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .loginRightContainer {
    margin: 0;
    width: 55%;
    padding: 15px 25px;
  }
  .loginRightPara {
    width: 100%;
    font-size: 13px;
  }
  .inputContainer {
    width: 100%;
  }
  .passwordInput {
    width: 90%;
  }
  .loginBtn {
    width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
}
