.notfound{
    /* width: 100%;
height: 100vh; */
display: flex;
justify-content: center;
align-items: center;
font-size: 30px;
color: red;
font-weight: 500;
}
.container{
    width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
}
.img{
    width: 50px;
    height: 50px;
}
.imgContainer{
    text-align: center;
}