.parentContainer {
  background-color: #f8f3f0;
  padding: 0 !important;
}

.childContainerParent {
  padding: 18px;
  min-height: calc(100vh - 68.5px);
}

.childContainer {
  background-color: white;
  padding: 0 !important;
}

.imgContainer {
  width: 40px;
  height: 40px;
}

.addText {
  color: white;
  align-self: center;
}

thead {
  background: #f5f5f5;
}

.paginationContainer {
  width: 100%;
  justify-content: end;
}

.img {
  width: 100%;
  height: 100%;
}

.imgTd {
  display: flex;
  justify-content: center;
  padding: 2px 0;
}

.productName {
  font-size: 12px;
  letter-spacing: 1px;
}

/* .productNameTh{
    width: 17%;
} */
.productCount {
  width: 60px;
  background: #f5f5f5;
  border: none;
  height: 33px;
  padding: 0px 8px;
  margin-left: 25px;
  outline: unset;
}

.searchParentContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.searchInputContainer {
  width: 30%;
}

.searchInput {
  width: 75%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  border-radius: 5px 0 0 5px;
  padding: 5px 15px;
  outline: unset;
}

.searchBtn {
  width: 150px;
  height: 37px;
  border: none;
  background: #ff7b26;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.resetBtn {
  width: 150px;
  height: 37px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.searchImg {
  width: 25px;
  height: 25px;
}

.readmore {
  cursor: pointer;
}

.IconChildContainer {
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.actionsIcon {
  width: 15px;
  height: 15px;
  align-self: center;
  cursor: pointer;
}

.action {
  display: flex;
  justify-content: center;
}

.EditPrice {
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: unset;
  width: 50px;
  padding: 5px 10px;
  text-align: center;
}

.editPrice {
  width: 13px;
  height: 13px;
  cursor: pointer;
  align-self: center;
}

.filterParentContainer {
  display: flex;
  justify-content: space-between;
  padding: 17px 38px;
  padding-top: 0;
}

.categories {
  width: 25%;
}

.filterInput {
  height: 37px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0 15px;
  outline: unset;
  width: 100%;
  margin: 5px 0;
}

.searchBtn {
  margin-bottom: 7px;
}

.tableHead {
  padding: 5px 0;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff7b26;
  width: 30px;
  height: 30px;
  align-self: center;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.viewImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.historyImg {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 5px;
}

.sellingPriceContainer {
  display: flex;
  justify-content: center;
  height: 39px;
}

.sellingPrice {
  width: 90px;
  text-align: end;
  display: flex;
}

.price {
  display: flex;
  justify-content: center;
}

.priceAmt {
  width: 75px;
  text-align: start;
  align-self: center;
}

.priceSymbol {
  text-align: right;
  padding-right: 5px;
  width: 25%;
  align-self: center;
}

.searchIcon {
  width: 20px;
  height: 20px;
  align-self: center;
  cursor: pointer;
  margin-right: 15px;
}

.CsvBtn {
  padding: 5px;
  height: 37px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.MonthlyReport {
  padding: 5px;

  height: 37px;
  border: 1px solid #ff7b26;
  background: white;
  border-radius: 5px;
  color: #ff7b26;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  gap: 5px;
  margin-left: 10px;
}

.download {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}

.popover {
  font-size: 14px;
  font-weight: 400;
  padding: 6px;
}

@media only screen and (max-width: 450px) {
  .TableChildContainer {
    width: 1300px !important;
  }

  .tableParentContainer {
    overflow-x: auto !important;
  }

  .actionsHead {
    width: 17%;
    font-size: 12px;
  }

  .tableHead {
    font-size: 13px !important;
  }

  .td {
    font-size: 11px !important;
  }

  .IconChildContainer {
    width: 20px;
    height: 20px;
  }

  .actionsIcon {
    width: 12px;
    height: 12px;
  }

  .filterParentContainer {
    display: block;
    padding: 17px 15px;
  }

  .categories {
    width: 100%;
  }

  .searchBtn {
    width: 50%;
  }

  .searchBtnParent {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
  }

  .searchParentContainer {
    padding: 10px 15px;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .TableChildContainer {
    width: 1200px !important;
  }

  .tableParentContainer {
    overflow-x: auto !important;
  }

  .actionsHead {
    width: 17%;
    font-size: 15px;
  }

  .tableHead {
    font-size: 15px;
  }

  .td {
    font-size: 13px;
  }

  .IconChildContainer {
    width: 25px;
    height: 25px;
  }

  .actionsIcon {
    width: 14px;
    height: 14px;
  }

  .filterParentContainer {
    flex-wrap: wrap;
  }

  .categories {
    width: 48%;
  }

  .searchBtnParent {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .TableChildContainer {
    width: 1200px !important;
  }

  .tableParentContainer {
    overflow-x: auto !important;
  }

  .actionsHead {
    width: 17%;
    font-size: 15px;
  }

  .tableHead {
    font-size: 15px;
  }

  .td {
    font-size: 13px;
  }

  .IconChildContainer {
    width: 25px;
    height: 25px;
  }

  .actionsIcon {
    width: 14px;
    height: 14px;
  }

  .filterParentContainer {
    flex-wrap: wrap;
  }

  .categories {
    width: 48%;
  }

  .searchBtnParent {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

@media (min-width: 993px) and (max-width: 1140px) {
  .actionsHead {
    width: 17%;
    font-size: 15px;
  }

  .tableHead {
    font-size: 15px;
  }

  .td {
    font-size: 13px;
  }

  .IconChildContainer {
    width: 25px;
    height: 25px;
  }

  .actionsIcon {
    width: 14px;
    height: 14px;
  }

  .filterParentContainer {
    flex-wrap: wrap;
  }

  .categories {
    width: 30%;
  }
}

@media (min-width: 1141px) and (max-width: 1200px) {
  .actionsHead {
    width: 13%;
    font-size: 15px;
  }

  .tableHead {
    font-size: 15px;
  }

  .td {
    font-size: 13px;
  }

  .IconChildContainer {
    width: 25px;
    height: 25px;
  }

  .actionsIcon {
    width: 14px;
    height: 14px;
  }
}