.cancelImg {
    width: 20px;
    height: 20px;
    align-self: center;
}

.cancelImgContainer {
    width: 30px;
    height: 30px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cancelImgParentContainer {
    display: flex;
    justify-content: space-between;
}

.msgIconContainer {
    display: flex;
    justify-content: center;
}

.select {
    font-size: 15px;
    letter-spacing: 1px;
    outline: unset;
    height: 37px;
    width: 100%;
    padding: 0 10px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin: 5px 0;
}

.msgIcon {
    width: 40px;
    height: 40px;
    align-self: center;
}

.msgIconParentContainer {
    width: 50px;
    height: 50px;
    /* background: #39B200; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.message {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.detailsParentContainer {
    max-width: 600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detail {
    font-size: 14px;
    font-weight: 600;
}

.ImgContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.btn {
    background-color: #F27F0C;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 17px;
    padding: 10px 46px;
    text-transform: uppercase;
    /* margin-left: 15px; */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.Resetbtn {
    background-color: white;
    border: 1px solid #F27F0C;
    border-radius: 6px;
    color: #F27F0C;
    font-size: 17px;
    padding: 10px 46px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 15px;
}

.textarea {
    font-size: 15px;
    letter-spacing: 1px;
    outline: unset;
    height: 80px;
    width: 97.5%;
    padding: 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 4px;
    resize: unset;
}

.btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
    margin-top: 10px;
}

.confirmationtextdisplay {
    display: flex;
    justify-content: center;
}

.confirmationText {
    font-weight: 600;
}

@media only screen and (max-width:450px) {
    .leftTitle {
        width: 149px;
    }

    .childParentContainer {
        width: 100%;
    }
}

@media (min-width:601px) and (max-width:768px) {}

@media (min-width:769px) and (max-width:992px) {}

@media (min-width:993px) and (max-width:1140px) {}

@media (min-width:1141px) and (max-width:1200px) {}