.overallContainer {
  background-color: #f8f3f0;
  width: 100%;
  height: calc(100vh - 68.5px);
  display: flex;
  justify-content: center;
}
/* .childContainer{
    min-width: 500px;
    margin: auto;
    background-color: white;
    height: 500px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */
.imgContainer {
  text-align: center;
  padding: 5px 0;
}
.img {
  width: 130px;
}
.input {
  height: 35px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 13px;
  outline: unset;
}
.select {
  height: 35px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
}
.remark {
  height: 110px;
  resize: unset;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 13px;
  outline: unset;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  /* padding: 10px 40px; */
  padding-bottom: 0;
}
.btn {
  background: #ff7b26;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 5px 25px;
  border-radius: 5px;
}
.cancelParent {
  position: absolute;
  right: 25px;
}
.cancelContainer {
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.cancelImg {
  width: 15px;
  height: 15px;
  align-self: center;
}
.btnContainer {
  padding: 10px 0;
  text-align: end;
}
.errMsg {
  font-size: 13px;
  font-weight: bold;
}
