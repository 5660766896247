
.overallPopupContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100vh;
}
table{
    width: 100% !important;
}
.popupBill{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}
.popupTitle{
    font-size: 15px;
    font-weight: bold;
}
.color{
    height: 20px;
width: 20px;
border-radius: 50%;
/* border: 1px solid white; */
margin: 0 0 0 7px;
cursor: pointer;
}
.border {
    width: 100%;
    height: 2px;
    border: 1px solid rgba(0,0,0,0.2);
    border-style: dashed;
    margin-top: 7px;
    padding: 0 20px;
}
.cancelBtn{
    border: 1px solid #F27F0C;
    background-color: white;
    font-size: 12px;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 10px;
}
/* .cancelBtn:hover{
    background-color: #F27F0C;
    color: white;
} */
.submitBtn{
    border: none;
    background-color: #F27F0C;
    color: white;
    font-size: 12px;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 5px;
    text-transform: uppercase;
}
/* .submitBtn:hover{
    border: 1px solid #F27F0C;
    background-color: white;
    color: black;
} */

.editPrice {
    width: 13px;
    height: 13px;
    cursor: pointer;
    margin-left: 5px;
  }
.del{
    width: 15px;
    cursor: pointer;
}
.tableContainer{
    padding: 5px 20px;
    min-height: 370px;
    overflow: auto;
}
.errorMsg{
    font-size: 10px;
    font-weight: bold;
    color: red;
}
.readmore{
    cursor: pointer;
}
.btnContainer{
    padding: 0 20px;
    display: flex;
    justify-content: end;
}
.saveImgContainer{
    width: 45px;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}
.minAmtError{
    color: red;
    font-size: 15px;
    text-align: end;
    padding: 10px;
    font-weight: 600;
}
.saveImg{
    width: 25px;
    align-self: center;
}
.imgContainer{
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
}
.billOptionlabel{
    font-size: 15px;
    font-weight: bold;
    padding-left: 12px;
    align-self: center;
    cursor: pointer;
}
.billOptionCheckbox{
    width: 18px;
    height: 18px;
    align-self: center;
    cursor: pointer;
}
.billOptionContainer{
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}
.img{
    width: 40px;
    height: 40px;
    object-fit: contain; border-radius: 20px;
}
.errorOption{
    font-size: 12px;
    font-weight: bold;
    color: red;
    padding: 0 27px;
}
.productContainer{
    display: flex;
    justify-content: left;
    margin: 15px !important;
}
.preserve-whitespace {
    white-space: pre-wrap;
  }
.unitContainer{
    font-weight: bold;
  }
.productCount{
    width: 60px;
    background: #f5f5f5;
    border: none;
    height: 33px;
    padding: 0px 8px;
    outline: unset;
}
.productDetails{
    width: 100%;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    margin-left: 25px;
}
.dropDownContainer{
    background-color: #f5f5f5;
    border: none;
    padding: 0px 8px;
    height: 33px;
    outline: unset;
}