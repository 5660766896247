.bgContainer{
    height: 100vh;
    padding: 0 !important;
    position: relative;
    display: flex;
    justify-content: center;
}
.leftBg{
    position: absolute;
    width: 700px;
    height: 500px;
    left: -198px;
    top: -171px;
    background: #FF7C25;
    filter: blur(1500px);
}
.rightBg{
    position: absolute;
    width: 758px;
    height: 430px;
    right: 0;
    top: -106px;
    background: #E52C6A;
    filter: blur(1000px);
}
.bottomLeft{
    position: absolute;
    width: 704px;
    height: 254px;
    left: -146px;
    top: 300px;
    background: #31054A;
    filter: blur(1000px);
}
.bottomRight{
    position: absolute;
    width: 700px;
    height: 950px;
    right: 0;
    height: 254px;
    top: 300px;
    background: #FDD12C;
    filter: blur(1500px);
}
.bg{
    width: 100%;
    height: 100vh;
    position: absolute;
    background: #000;
}
.loginContainer{
    max-width: 450px;
    float: left;
    position: relative;
    align-self: center;
    margin: 15px;
    text-align: center;
}
.loginImg{
    width: 70%;
}
.loginLeftContainer{
    width: 45%;
    float: left;
    background-color: rgba(0,0,0,0.02);
    height: 600px;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width:600px) {
    .loginLeftContainer{
        display: none;
    }
    .leftBg{
        width: 100%;
    }
    .bottomLeft{
        width: 100%;
    } 
}
