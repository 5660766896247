.settingsImg{
    width: 18px;
}
.leftContainer{
    width: 25%;
    float: left;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}
.rightContainer{
    width: 43%;
    float: left;
    display: flex;
    justify-content: end;
}
.showbillText{
    background: #ff832d;
color: white;
align-self: center;
font-size: 14px;
font-weight: bold;
padding: 5px 20px;
border-radius: 5px;
cursor: pointer;
}
.settings2Container{
    width: 33px;
    height: 33px;
    background: rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.addContainer{
    width: 33px;
    height: 33px;
    background: #FF842E;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.dateContainer{
    width: 70% !important;
    background: rgba(0,0,0,0.03);
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.overallContainer{
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}
.hrContainer{
    float: left;
    display: flex;
}
.hr1{
    height: 3px !important;
    width: 15%;
    color: #FB9952;
}
.hr2{
    height: 3px !important;
    width: 85%;
}
.td{
    text-align: center;
    font-size: 15px;
}
table{
    width: 85%;
}
.tableImgContainer{
    width: 35px;
    height: 35px;
    background: #F8AC94;
    border-radius: 6px;
    display: flex;
    justify-content: center;
}
.tableContainer{
    width: 100%;
    float: left;
    margin: 7px 0;
}
.mapContainer{
    width: 100%;
    float: left;
    max-height: 490px;
    overflow: auto;
}
.date{
    font-size: 12px;
    font-weight: bold;
    align-self: center;
}
.dateImg{
    width: 19px;
}
.td{
    font-size: 13px;
    align-self: center;
}
/* 
.tr{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
} */

.header{
    position: sticky;
    top: 0;
}
@media only screen and (max-width:450px) {
    .rightContainer{
        width: 100% !important;
        padding-top: 15px;
    }
    .td{
        font-size: 10px !important;
    }
    .overallContainer{
        display: block;
    }
    .leftContainer{
        width: 55%;
        justify-content: space-evenly;
    }
    .rdrDateRangePickerWrapper{
        right: 4%;
        top: 15%;
    }
    .tableContainer{
        width: 655px;
    }
    .tableParentContainer{
        overflow-x: auto;
    }
}


@media (min-width:451px) and (max-width:768px) {
    .rightContainer{
        width: 70% !important;
    }
    .td{
        font-size: 10px !important;
    }
}

@media (min-width:769px) and (max-width:992px) {
    .leftContainer{
        width: 17%;
    }
}

@media (min-width:923px) and (max-width:1140px) {
    
}

@media (min-width:1141px) and (max-width:1200px) {
    
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    
    }