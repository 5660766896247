

.title{
    font-weight: 700;
    }

    .radioButtonContainer {
        display: flex;
        padding: 2px 0;
      }

      .radioButton {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
      .buttonLabel {
        padding-left: 15px;
        cursor: pointer;
        align-self: center;
      }
   
      .submitButton{
        border: none;
        background-color: #F27F0C;
        color: white;
        font-size: 12px;
        padding: 10px 30px;
        font-weight: bold;
        border-radius: 5px;
        margin-top: 15px;
        text-transform: uppercase;
    }
      .contentContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }